// src/components/CheckoutPage.js
import React, { useRef, useState } from "react";
import Header from "../../components/Header";
import Logo from "../../assets/images/logos/logo2.png";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  clearCart,
  decreaseItemQuantity,
  increaseItemQuantity,
  removeItemFromCart,
} from "../../Redux/Slice/CartSlice";
import { loadStripe } from "@stripe/stripe-js";
import {
  useAddNewUserMutation,
  useLoginMutation,
  useVerifyRecaptchaMutation,
} from "../../Redux/Services/authServices";
import {
  setAccessToken,
  setRefreshToken,
  setUser,
} from "../../Redux/Slice/authSlice";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
import { BASE_URL } from "../../Utils/constant";
import ReCAPTCHA from "react-google-recaptcha";

async function showToastAndNavigate() {
  await toast.success("order place sucessfully");
  return Promise.resolve();
}

const CorporateCheckout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptcha = useRef();
  const [verifyRecaptcha] = useVerifyRecaptchaMutation();
  const [addNewUser] = useAddNewUserMutation();
  const [login] = useLoginMutation();
  const { user } = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart.cart);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");

  const handleCountryChange = (val) => {
    setCountry(val);
    setRegion("");
  };

  const handleRegionChange = (val) => {
    setRegion(val);
  };
  const [fieldValidations, setFieldValidations] = useState({
    firstName: {
      value: "",
      isValid: true,
      errorMessage: "Please fill Required filled",
    },
    lastName: {
      value: "",
      isValid: true,
      errorMessage: "Please fill Required filled",
    },
    mobileNo: { value: "", isValid: true, errorMessage: "" },
    email: { value: "", isValid: true, errorMessage: "" },
    password: { value: "", isValid: true, errorMessage: "" },
    streetName: { value: "", isValid: true, errorMessage: "" },
    // streetNo: { value: "", isValid: true, errorMessage: "" },
    postalCode: { value: "", isValid: true, errorMessage: "" },
    townCity: { value: "", isValid: true, errorMessage: "" },
    country: { value: "", isValid: true, errorMessage: "" },

    province: { value: "", isValid: true, errorMessage: "" },
  });

  const [termsChecked, setTermsChecked] = useState(false);

  const handleTermsChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handleInputChange = (fieldName) => (event) => {
    const inputValue = event.target.value.trim();

    setFieldValidations((prevValidations) => ({
      ...prevValidations,
      [fieldName]: {
        value: inputValue,
        isValid: inputValue !== "",
        errorMessage: inputValue !== "" ? "" : "Field is required.",
      },
    }));

    // Other field validations
  };

  const calculateTotal = (cart) => {
    let total = 0;

    cart?.forEach((item) => {
      const quantity = item?.quantity;
      const price = item?.courses?.price;
      let discountedPrice = price;

      // Apply discount based on quantity
      if (quantity >= 3 && quantity < 5) {
        discountedPrice = price * 0.98; // 2% discount for 3 or more courses
      } else if (quantity >= 5) {
        discountedPrice = price * 0.95; // 5% discount for 5 or more courses
      }

      total += discountedPrice * quantity;
    });

    return total.toFixed(2);
  };

  const handlePlaceOrder = async (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      toast.info("Please verify reCAPTCHA!");
      return;
    }

    try {
      const recaptchaResponse = await verifyRecaptcha({
        captchaValue: captchaValue,
      });
      setIsButtonClicked(true);
      const validations = Object.keys(fieldValidations).reduce(
        (result, fieldName) => {
          const inputValue = fieldValidations[fieldName].value.trim();
          // Skip email and password validation if the user exists
          if (fieldName === "email" && user) {
            result[fieldName] = {
              value: inputValue,
              isValid: true,
              errorMessage: "",
            };
          } else if (fieldName === "password" && user) {
            result[fieldName] = {
              value: inputValue,
              isValid: true,
              errorMessage: "",
            };
          } else {
            // Perform specific validations based on field name
            switch (fieldName) {
              case "firstName":
                if (user && user?.name?.split(" ")[0]) {
                  break;
                }
                result[fieldName] = {
                  value: inputValue,
                  isValid: /^[A-Za-z\s]+$/.test(inputValue), // Name should contain only letters and spaces
                  errorMessage: /^[A-Za-z\s]+$/.test(inputValue)
                    ? ""
                    : "Invalid first Name format.",
                };
                break;
              case "lastName":
                if (user && user?.name?.split(" ")[1]) {
                  break;
                }
                result[fieldName] = {
                  value: inputValue,
                  isValid: /^[A-Za-z\s]+$/.test(inputValue), // Name should contain only letters and spaces
                  errorMessage: /^[A-Za-z\s]+$/.test(inputValue)
                    ? ""
                    : "Invalid last Name format.",
                };
                break;
              case "email":
                if (user) {
                  break;
                }
                result[fieldName] = {
                  value: inputValue,
                  isValid: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue), // Basic email validation
                  errorMessage: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)
                    ? ""
                    : "Invalid email address.",
                };
                break;
              case "mobileNo":
                if (user && user?.mobileNo) {
                  break;
                }
                result[fieldName] = {
                  value: inputValue,
                  isValid: /^\d{10}$/.test(inputValue), // Mobile number should be 10 digits
                  errorMessage: /^\d{10}$/.test(inputValue)
                    ? ""
                    : "Invalid mobile number.",
                };
                break;
              case "townCity":
                result[fieldName] = {
                  value: inputValue,
                  isValid: /^[A-Za-z\s]+$/.test(inputValue), // Town/City should contain only letters and spaces
                  errorMessage: /^[A-Za-z\s]+$/.test(inputValue)
                    ? ""
                    : "Invalid town/city name format.",
                };
                break;
              case "postalCode":
                result[fieldName] = {
                  value: inputValue,
                  isValid: /^[A-Za-z0-9\s]+$/.test(inputValue), // Postal code can contain letters, numbers, and spaces
                  errorMessage: /^[A-Za-z0-9\s]+$/.test(inputValue)
                    ? ""
                    : "Invalid postal code format.",
                };
                break;
              case "password":
                if (user) {
                  break;
                }
                result[fieldName] = {
                  value: inputValue,
                  isValid: inputValue.length >= 8, // Password should be minimum 8 characters long
                  errorMessage:
                    inputValue.length >= 8
                      ? ""
                      : "Password must be at least 8 characters long.",
                };
                break;
              default:
                result[fieldName] = {
                  value: inputValue,
                  isValid: inputValue !== "",
                  errorMessage: inputValue !== "" ? "" : "Field is required.",
                };
                break;
            }
          }

          return result;
        },
        {}
      );
      setFieldValidations(validations);

      const areAllFieldValid = Object.values(validations).every(
        (validation) => validation.isValid
      );

      if (!areAllFieldValid) {
        const element = document.getElementById("checkoutFormTop");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
        toast.error("Please correct the errors in the form.");

        return;
      }

      if (recaptchaResponse?.data?.success) {
        let userId = user?._id;

        if (!user) {
          const firstNameOrder = document.getElementById("firstName");
          const LastNameOrder = document.getElementById("lastName");
          const mobileNoInput = document.getElementById("mobileNo");
          const emailInput = document.getElementById("email");
          const passwordInput = document.getElementById("password");

          const registrationData = {
            name: `${firstNameOrder?.value} ${LastNameOrder?.value}` || "",
            mobileNo: mobileNoInput?.value || "",
            email: emailInput?.value || "",
            password: passwordInput?.value || "",
          };

          const res = await addNewUser(registrationData);

          if (res.data) {
            if (res.data.status === "ok") {
              const response = await login({
                email: registrationData.email,
                password: registrationData.password,
              });

              const userData = response?.data?.data;
              if (response?.data?.data) {
                dispatch(setUser(response?.data?.data));
                dispatch(setAccessToken(response?.data?.data?.accessToken));
                dispatch(setRefreshToken(response?.data?.data?.refreshToken));
                localStorage.setItem("userData", JSON.stringify(userData));

                localStorage.setItem(
                  "authToken",
                  response?.data?.data?.accessToken
                );
                localStorage.setItem(
                  "refreshToken",
                  response?.data?.data?.refreshToken
                );
                userId = response?.data?.data?._id;

                console.log("User registered successfully:");
                toast.success("User registered successfully!");
              } else {
                console.error("User registration failed");
                toast.error("User registration failed!");
              }
            } else if (
              res.data.status === "Failed" &&
              res.data.msg === "Email aleardy Exist"
            ) {
              console.error("User is already registered");
              toast.error(res.data.msg);
            } else {
              console.error("User registration failed");
              toast.error(res.data.msg);
            }
          } else {
            console.error("User registration failed");
            toast.error(res.data.msg);
          }
        }

        setIsButtonDisabled(true);

        const stripe = await loadStripe(
          "pk_test_51OSkjcSJg3MN1lkAZf2rmb6xQMOtdUsGBUXixhXj1v28a4EU0maEQhHMjf2goTeYg3ulvSSVtyHqnvGMBXD1aaaC008fpyFVEk"
        );
        const firstNameOrder = document.getElementById("firstName");
        const LastNameOrder = document.getElementById("lastName");

        const mobileOrder = document.getElementById("mobileNo");
        const companyorder = document.getElementById("companyName");
        const streetOrder = document.getElementById("streetName");
        const streetNoOrder = document.getElementById("streetNo");
        const postalCorder = document.getElementById("postalCode");
        const townCityOrder = document.getElementById("townCity");
        const provinceOrder = document.getElementById("province");
        const countryorder = document.getElementById("country");

        const orderData = {
          userId: userId,
          // courseId: cart[0]?.courses[0]?._id,
          name: `${firstNameOrder?.value} ${LastNameOrder?.value}` || "",
          mobileNo: mobileOrder?.value || "",
          companyName: companyorder?.value || "",
          streetName: streetOrder?.value || "",
          streetNo: streetNoOrder?.value || "",
          postalCode: postalCorder?.value || "",
          townCity: townCityOrder?.value || "",
          province: provinceOrder?.value || "",
          country: countryorder?.value || "",
          courses: cart.map((item) => ({
            _id: item?.courses?._id,
            title: item?.courses?.title,
            description: item?.courses?.description,
            price: item?.courses?.price,
            courseValidity: item?.courses?.courseValidityDays,
            quantity: item?.quantity,
          })),
        };

        const response = await axios.post(
          `${BASE_URL}/api/create-checkout-session`,
          orderData
        );
        if (response.data.sessionId) {
          const result = stripe.redirectToCheckout({
            sessionId: response.data.sessionId,
          });
          await showToastAndNavigate();
          setTimeout(() => {
            navigate("/dashboard");
          }, 6000);

          if (result.error) {
            toast.error("Error in placing order");
          }

          dispatch(clearCart());
          setIsButtonDisabled(false);
        }

        if (
          response.data.message === "Orders and payments updated successfully"
        ) {
          toast.success("order placed sucessfully");
          setTimeout(() => {
            navigate("/dashboard");
          }, 3000);
        }
        dispatch(clearCart());
        setIsButtonDisabled(false);
      } else {
        console.log(recaptchaResponse);
        recaptcha.current.reset();
        toast.error("failed to Verify captcha");
      }
    } catch (error) {
      console.error("Error placing the orderss:", error);
      setIsButtonDisabled(false);
    }
  };

  // const handleLoginOrRegisterClick = () => {
  //   localStorage.setItem("checkoutRedirectUrl", window.location.href);
  // };

  const handleRemoveItem = (itemId, quantity) => {
    if (quantity > 1) {
      dispatch(decreaseItemQuantity(itemId));
    } else {
      dispatch(removeItemFromCart(itemId));
    }
  };

  const handleIncreaseQuanity = (itemId, quantity) => {
    dispatch(increaseItemQuantity(itemId));
  };

  const handleCountryChangeoption = (val) => {
    setFieldValidations((prevValidations) => ({
      ...prevValidations,
      country: {
        value: val,
        isValid: val !== "",
        errorMessage: val !== "" ? "" : "Field is required.",
      },
    }));

    handleCountryChange(val);
  };

  const handleRegionChangeption = (val) => {
    setFieldValidations((prevValidations) => ({
      ...prevValidations,
      province: {
        value: val,
        isValid: val !== "",
        errorMessage: val !== "" ? "" : "Field is required.",
      },
    }));

    handleRegionChange(val);
  };

  return (
    <>
      <Header
        parentMenu="course"
        menuCategoryEnable="enable"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
      />
      <Breadcrumb pageTitle="Checkout" />

      <div className="flex justify-center items-center px-5 py-5 bg-white">
        <div className="px-4 sm:px-6 lg:px-8 w-full container mx-auto">
          <div className="text-center text-main mb-2">
            {!user && (
              <>
                <p className="inline">Returning Customer? </p>
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="inline bg-main py-2 px-2 mb-2 rounded-md hover:bg-secondry text-sm"
                >
                  Click Here to Login
                </button>
              </>
            )}
          </div>

          <div className="flex flex-col gap-8">
            <div className="check">
              <h2 className="text-2xl font-semibold text-main mb-4">
                Personal and Account Details
              </h2>
              <div
                id="checkoutFormTop"
                className="grid grid-cols-1 md:grid-cols-2 gap-4"
              >
                <div className="mb-4">
                  <label
                    htmlFor="firstName"
                    className="block  font-medium mb-1"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={user?.name.split(" ")[0]}
                    placeholder="Enter your first name"
                    className="w-full border border-gray-300 rounded-md p-2"
                    onChange={handleInputChange("firstName")} // Add this line
                    required={!user} // Make the field required only when the user is not logged in
                  />
                  {isButtonClicked && !fieldValidations?.firstName?.isValid && (
                    <p className="text-red-500">
                      {fieldValidations?.firstName?.errorMessage}
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <label htmlFor="lastName" className="block  font-medium mb-1">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={user?.name.split(" ")[1]}
                    placeholder="Enter your Last name"
                    className="w-full border border-gray-300 rounded-md p-2"
                    onChange={handleInputChange("lastName")} // Add this line
                    required={!user} // Make the field required only when the user is not logged in
                  />
                  {isButtonClicked && !fieldValidations?.lastName?.isValid && (
                    <p className="text-red-500">
                      {fieldValidations?.lastName?.errorMessage}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label htmlFor="mobileNo" className="block  font-medium mb-1">
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    id="mobileNo"
                    name="mobileNo"
                    value={user?.mobileNo}
                    placeholder="Enter your Mobile Number"
                    className="w-full border border-gray-300 rounded-md p-2"
                    onChange={handleInputChange("mobileNo")} // Add this line
                    required={!user} // Make the field required only when the user is not logged in
                  />
                  {isButtonClicked && !fieldValidations?.mobileNo?.isValid && (
                    <p className="text-red-500">
                      {fieldValidations?.mobileNo?.errorMessage}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="companyName"
                    className="block  font-medium mb-1"
                  >
                    Company Name (optional)
                  </label>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    placeholder="Enter company name"
                    onChange={handleInputChange} // Add this line
                    className="w-full border border-gray-300 rounded-md p-2"
                  />
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="streetName"
                    className="block  font-medium mb-1"
                  >
                    Country
                  </label>
                  <CountryDropdown
                    id="country"
                    name="country"
                    value={fieldValidations.country.value}
                    onChange={(val) => handleCountryChangeoption(val)}
                    className="w-full border border-gray-300 rounded-md p-2"
                  />
                  {isButtonClicked && !fieldValidations.country.isValid && (
                    <p className="text-red-500">
                      {fieldValidations.country.errorMessage}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="streetName"
                    className="block  font-medium mb-1"
                  >
                    Province
                  </label>
                  <RegionDropdown
                    id="province"
                    name="province"
                    country={fieldValidations.country.value}
                    value={fieldValidations.province.value}
                    onChange={(val) => handleRegionChangeption(val)}
                    className="w-full border border-gray-300 rounded-md p-2"
                    disableWhenEmpty
                  />
                  {isButtonClicked && !fieldValidations.province.isValid && (
                    <p className="text-red-500">
                      {fieldValidations.province.errorMessage}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label htmlFor="townCity" className="block  font-medium mb-1">
                    Town / City
                  </label>
                  <input
                    type="text"
                    id="townCity"
                    name="townCity"
                    placeholder="Enter town or city"
                    onChange={handleInputChange("townCity")} // Add this line
                    className="w-full border border-gray-300 rounded-md p-2"
                    required
                  />
                  {isButtonClicked && !fieldValidations.townCity.isValid && (
                    <p className="text-red-500">
                      {fieldValidations.townCity.errorMessage}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="streetName"
                    className="block  font-medium mb-1"
                  >
                    Street Name & Number
                  </label>
                  <input
                    type="text"
                    id="streetName"
                    name="streetName"
                    placeholder="Enter street name"
                    onChange={handleInputChange("streetName")} // Add this line
                    className="w-full border border-gray-300 rounded-md p-2"
                    required
                  />
                  {isButtonClicked && !fieldValidations.streetName.isValid && (
                    <p className="text-red-500">
                      {fieldValidations.streetName.errorMessage}
                    </p>
                  )}
                </div>

                {/* <div className="mb-4">
                  <label htmlFor="streetNo" className="block  font-medium mb-1">
                    Street No.
                  </label>
                  <input
                    type="text"
                    id="streetNo"
                    name="streetNo"
                    placeholder="Enter street no."
                    onChange={handleInputChange("streetNo")} // Add this line
                    className="w-full border border-gray-300 rounded-md p-2"
                    required
                  />
                  {isButtonClicked && !fieldValidations.streetNo.isValid && (
                    <p className="text-red-500">
                      {fieldValidations.streetNo.errorMessage}
                    </p>
                  )}
                </div> */}

                <div className="mb-4">
                  <label
                    htmlFor="postalCode"
                    className="block  font-medium mb-1"
                  >
                    Postal Code
                  </label>
                  <input
                    type="text"
                    id="postalCode"
                    name="postalCode"
                    placeholder="Enter postal code"
                    onChange={handleInputChange("postalCode")} // Add this line
                    className="w-full border border-gray-300 rounded-md p-2"
                    required
                  />
                  {isButtonClicked && !fieldValidations.postalCode.isValid && (
                    <p className="text-red-500">
                      {fieldValidations.postalCode.errorMessage}
                    </p>
                  )}
                </div>
              </div>

              {!user && (
                <>
                  <h2 className="text-2xl font-semibold text-main mb-4">
                    Account Creation{" "}
                  </h2>
                  <div className="flex">
                    <div className="mb-4 w-100 px-2">
                      <label
                        htmlFor="email"
                        className="block  font-medium mb-1"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Enter your Email"
                        className="w-full border border-gray-300 rounded-md p-2"
                        onChange={handleInputChange("email")}
                        required={!user}
                      />
                      <p className="">
                        This email will be used to create your new account.
                      </p>
                      {isButtonClicked && !fieldValidations.email.isValid && (
                        <p className="text-red-500">
                          {fieldValidations.email.errorMessage}
                        </p>
                      )}
                    </div>

                    <div className="mb-4 w-100 px-2">
                      <label
                        htmlFor="password"
                        className="block  font-medium mb-1"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Enter Your Password"
                        className="w-full border border-gray-300 rounded-md p-2"
                        onChange={handleInputChange("password")}
                        required={!user}
                      />
                      <p>
                        Create a password that will keep your new account safe.
                      </p>
                      {isButtonClicked &&
                        !fieldValidations.password.isValid && (
                          <p className="text-red-500">
                            {fieldValidations.password.errorMessage}
                          </p>
                        )}
                    </div>
                  </div>
                </>
              )}
              <div className="mb-4">
                <label htmlFor="terms" className="block  font-medium mb-1">
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    checked={termsChecked}
                    onChange={handleTermsChange}
                    className="mr-2"
                  />
                  I accept the terms and conditions{" "}
                  <Link className="underline">Terms and condition</Link>
                </label>
                {isButtonClicked && !termsChecked && (
                  <p className="text-red-500">
                    Please accept the terms and conditions.
                  </p>
                )}
              </div>
            </div>

            <div className="check">
              <h2 className="text-2xl font-semibold text-main mb-4 ">
                Order Details
              </h2>
              <div className="bg-white p-2 rounded">
                {" "}
                <div className="mb-4 p-2">
                  <h3 className="text-xl font-semibold mb-2">
                    Items in Your Order
                  </h3>
                  {/* Replace this with your actual order details logic */}
                  <div className="flex flex-col space-y-2">
                    {console.log(cart)}
                    {cart?.map((item) => (
                      <div key={item?.courses?._id} className="">
                        <div>{item?.courses?.title}</div>
                        <div className="flex pt-2 justify-between">
                          <div>${item?.courses?.price}</div>
                          <div className="flex items-center">
                            <button
                              className="bg-black text-white text-xl md:text-md px-2 rounded-full"
                              onClick={() =>
                                handleRemoveItem(
                                  item?.courses?._id,
                                  item?.quantity
                                )
                              }
                            >
                              -
                            </button>
                            <span className="font-semibold mx-2">
                              {item?.quantity}
                            </span>
                            <button
                              className="bg-black text-white text-xl md:text-2xl px-2 rounded-full"
                              onClick={() =>
                                handleIncreaseQuanity(
                                  item?.courses?._id,
                                  item?.quantity
                                )
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="border-t border-gray-300 pt-2 ">
                  <div className="flex justify-between">
                    <span className="font-medium p-2">Total</span>
                    <span className="font-semibold pr-10">
                      ${calculateTotal(cart)}
                    </span>
                  </div>
                </div>
                {/* Payment method selection */}
                <div className="mb-4 mt-4">
                  <button
                    type="submit"
                    className={`check-btn py-2 px-2 mt-2 rounded-md hover:bg-secondry text-lg ${
                      isButtonDisabled ? "opacity-50" : ""
                    }`}
                    onClick={(e) => handlePlaceOrder(e)}
                    disabled={isButtonDisabled} // Disable the button when isButtonDisabled is true
                  >
                    {isButtonDisabled ? "Processing..." : "Place Order"}
                  </button>
                </div>
                <div className="flex mt-2">
                  <ReCAPTCHA
                    ref={recaptcha}
                    sitekey={process.env.REACT_APP_SITE_KEY}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CorporateCheckout;
