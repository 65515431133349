import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb/CourseBreadcrumbs";
import CourseDetailsMain from "./CourseDetailsMain";
import ScrollToTop from "../../components/ScrollTop";
import courses from "../../data/Courses.json";

import Logo from "../../assets/images/logos/logo2.png";
import { useDispatch, useSelector } from "react-redux";
import { useGetCourseByIdQuery } from "../../Redux/Services/Courseservice";
import { setSelectedCourse } from "../../Redux/Slice/CourseSlice";
import { setIsTableLoading } from "../../Redux/Slice/authSlice";

const CourseDetails = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();

  const { data, isFetching, isLoading } = useGetCourseByIdQuery(slug);
  const { selectedCourse, isTableLoading } = useSelector(
    (state) => state.course
  );
  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setSelectedCourse(data?.data));
    } else {
      dispatch(setIsTableLoading(true));
    }
  }, [dispatch, data, isFetching, isLoading]);

  return (
    <body className="course-single">
      <Header
        parentMenu="course"
        menuCategoryEnable="enable"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
      />

      <div className="react-wrapper">
        <div className="react-wrapper-inner">
          <Breadcrumb
            courseBannerImg={`${selectedCourse?.coverImage}`}
            courseTitle={selectedCourse?.title}
            courseName={selectedCourse?.name}
            courseAuthor={selectedCourse?.author}
            courseAuthorImg={selectedCourse?.authorImg}
          />

          <CourseDetailsMain
            course={selectedCourse}
            courseID={selectedCourse?.id}
            courseImg={`${selectedCourse?.image}`}
            courseTitle={selectedCourse?.title}
            courseSubTitle={selectedCourse?.subtitle}
            courseDescription={selectedCourse?.description}
            courseOverView={selectedCourse?.overview}
            courseRating={selectedCourse?.rating}
            coursePrice={selectedCourse?.price}
            courseCoverVideo={selectedCourse?.coverVideo}
            courseCoverImage={selectedCourse?.coverVideo}
            courseDuration={selectedCourse?.courseDuration}
            courseLanguage={"English"}
          />

          {/* scrolltop-start */}
          <ScrollToTop />
          {/* scrolltop-end */}
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default CourseDetails;
