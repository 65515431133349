import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLogoutMutation } from "../../Redux/Services/authServices";
import { setUser } from "../../Redux/Slice/authSlice";
import { useDispatch } from "react-redux";

const Menu2 = ({ parentMenu, setMenuOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [logout] = useLogoutMutation();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      dispatch(setUser(null));
      await logout();
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userData");
      setMenuOpen(false);  // Close menu on logout
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <>
      <li className={location.pathname === "/" ? "menu-active" : ""}>
        <Link to="/dashboard" onClick={() => setMenuOpen(false)}>Dashboard</Link>
      </li>
      <li className={location.pathname === "/receipt" ? "menu-active" : ""}>
        <Link to="/dashboard/receipt" onClick={() => setMenuOpen(false)}>Receipt</Link>
      </li>
      <li className={location.pathname === "/accountinfo" ? "menu-active" : ""}>
        <Link to="/dashboard/accountinfo" onClick={() => setMenuOpen(false)}>Account Info</Link>
      </li>
      <li className={location.pathname === "/contact" ? "menu-active" : ""}>
        <Link to="/contact" onClick={() => setMenuOpen(false)}>Contact</Link>
      </li>
      <li className={location.pathname === "/login" ? "menu-active" : ""}>
        <Link to="/login" onClick={handleLogout}>Logout</Link>
      </li>
    </>
  );
};

export default Menu2;
