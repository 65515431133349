import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Menu2 from "./menu2";
import { useSelector } from "react-redux";
import normalLogo from "../../assets/images/logos/logo.png";
import stickyLogo from "../../assets/images/logos/logo.png";

const Header2 = (props) => {
  const {
    topbarEnable,
    menuCategoryEnable,
    headerClass,
    parentMenu,
    headerNormalLogo,
    headerStickyLogo,
  } = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    // Sticky is displayed after scrolling for 100 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      <header
        id="react-header"
        className={
          headerClass ? headerClass : "react-header react-header-three"
        }
      >
        <div className={isVisible ? "header-area react-sticky" : "header-area"}>
          <div className="menu-part">
            <div className="container">
              <div className="react-main-menu">
                <nav>
                  <div className="menu-toggle col-lg-2">
                    <div className="logo">
                      {isVisible ? (
                        <Link to="/" className="logo-text">
                          <img
                            src={
                              headerStickyLogo ? headerStickyLogo : stickyLogo
                            }
                            alt=""
                          />
                        </Link>
                      ) : (
                        <Link to="/" className="logo-text">
                          <img
                            src={
                              headerNormalLogo ? headerNormalLogo : normalLogo
                            }
                            alt=""
                          />
                        </Link>
                      )}
                    </div>
                    <button
                      type="button"
                      id="menu-btn"
                      className={
                        menuOpen ? "mobile-menu-btn open" : "mobile-menu-btn"
                      }
                      onClick={() => setMenuOpen(!menuOpen)}
                    >
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                  </div>
                  <div
                    className={
                      menuOpen
                        ? "react-inner-menus menu-open"
                        : "react-inner-menus"
                    }
                  >
                    <ul id="backmenu" className="react-menus react-sub-shadow">
                      <Menu2 parentMenu={parentMenu} setMenuOpen={setMenuOpen} />
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header2;
