import React, { useEffect, useState } from "react";
import CourseContentRow from "../../components/Single Course/CourseContentRow";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  setIsTableLoading,
  setSelectedCourse,
} from "../../Redux/Slice/CourseSlice";
import {
  useGetCourseByIdQuery,
  useGetCompletionCertificateQuery,
  useGetFinalQuizResultQuery,
} from "../../Redux/Services/Courseservice";
import axios from "axios";
import { API_HEADER, BASE_URL } from "../../Utils/constant";
import { GiPartyPopper } from "react-icons/gi";

const CourseModule = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const paramss = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get("orderId") || localStorage.getItem("currentOrderId");
  if (orderId) {
    localStorage.setItem("currentOrderId", orderId);
  }

  const { selectedCourse } = useSelector((state) => state.course);
  if (selectedCourse) {
    localStorage.setItem("selectedCourseId", selectedCourse._id);
  }
  const [courseId, setCourseId] = useState(
    selectedCourse?._id
      ? selectedCourse._id
      : localStorage.getItem("selectedCourseId")
  );
  const courseSlug = paramss.slug;
  const { user } = useSelector((state) => state.auth);
  const userId = user._id;
  const { data, isFetching, isLoading } = useGetCourseByIdQuery(courseSlug);
  const [userProgress, setUserProgress] = useState(null);
  const [courseCertificate, setCourseCertificate] = useState(null);
  const [quizResults, setQuizResult] = useState(null);
  const [finalQuizResult, setFinalQuizResult] = useState(null);

  const { data: finalQuizResultQ, isLoading: isFinalQuizLoading } =
    useGetFinalQuizResultQuery({
      userId: userId,
      courseId: courseId,
      orderId: orderId,
    });

  useEffect(() => {
    if (finalQuizResultQ) {
      let status = finalQuizResultQ?.result?.completed ? "Completed" : null;
      setFinalQuizResult(status);
    }
  }, [finalQuizResultQ, courseId]);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setSelectedCourse(data?.data));
      setCourseId(data?.data?._id);
    } else {
      dispatch(setIsTableLoading(true));
    }
  }, [dispatch, data, isFetching, isLoading, finalQuizResult]);

  const getCourseCompletionCertificate = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/course/completionCertificate`,
        {
          userId: userId,
          courseId: courseId,
          courseTitle: data.data.title,
          userName: user?.name,
          orderId: orderId ? orderId : localStorage.getItem("currentOrderId"),
        },
        API_HEADER
      );
      if (response.data.success) {
        setCourseCertificate(response.data.certificate);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const changeCourseCompletionStatus = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/changeCourseCompletionStatus`,
        { courseId: courseId, orderId: orderId }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const calculateProgress = () => {
    if (!userProgress || !selectedCourse || !selectedCourse.modules) {
      return 0;
    }

    let completedModules = 0;
    let quizCompleted = 0;

    selectedCourse.modules.forEach((module) => {
      const userModule = userProgress.moduleProgress.find(
        (userModule) => userModule.moduleId === module._id
      );

      if (
        userModule &&
        module.parts.every((part) =>
          userModule.partsCompleted.some(
            (completedPart) => completedPart.partNumber === part.partNumber
          )
        )
      ) {
        completedModules++;
      }
    });

    quizResults &&
      quizResults?.forEach((element) => {
        if (element.status === "Completed") {
          quizCompleted++;
        }
      });

    const totalModules = selectedCourse?.modules?.length;
    const totalQuiz = quizResults?.length;
    const finalQuizCompletedPercentage =
      finalQuizResult === "Completed" ? 10 : 0;
    const percentage = Math.round(
      ((completedModules + quizCompleted) / (totalModules + totalQuiz)) * 90 +
        finalQuizCompletedPercentage
    );
    if (percentage === 100) {
      changeCourseCompletionStatus();
    }

    return percentage;
  };
  const [progress, setProgress] = useState(calculateProgress());
  useEffect(() => {
    const fetchUserProgress = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/user-progress/${userId}/${courseId}/${orderId}`
        );
        setUserProgress(response.data);
      } catch (error) {
        console.error("Error fetching user progress:", error);
      }
    };
    const fetchUserCertificate = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/getCertificateByCourseAndUser/${userId}/${courseId}/${orderId}`
        );
        if (response.data.success) {
          setCourseCertificate(response.data.data.certificate);
        }
      } catch (error) {
        console.error("Error fetching user progress:", error);
      }
    };
    if (courseId) {
      fetchUserCertificate();
      fetchUserProgress();
    }
  }, [data, userId, courseId]);

  // Update progress state when userProgress or selectedCourse changes
  useEffect(() => {
    // Update progress state when userProgress or selectedCourse changes
    setProgress(calculateProgress());
  }, [userProgress, selectedCourse, quizResults]);

  useEffect(() => {
    if (!courseCertificate && progress === 100) {
      getCourseCompletionCertificate();
    }
  }, [progress]);

  return (
    <div className="w-full min-h-screen flex px-2 pt-14 md:p-4">
      <div className="w-full">
        <h1 className="text-xl text-main mb-4">{selectedCourse?.title}</h1>
        <div className="shadow-md bg-white p-4 rounded-md border">
          <h4>In progress</h4>
          <div className="w-full bg-gray-300 rounded-full overflow-hidden mt-4">
            <div
              className="bg-green-500 text-white  text-center pr-4"
              style={{ width: `${progress}%` }}
            >
              {progress}%
            </div>
          </div>
        </div>

        {courseCertificate && courseCertificate.url && (
          <div className="p-4 shadow-lg rounded-md bg-slate-50 mt-2 border">
            <span className="flex items-center">
              <h3 className="capitalize font-mono font-thin py-2 pr-8 bg-green-500 bg-clip-text text-transparent ">
                Hey!,You Earned a Certitificate{" "}
              </h3>
              <GiPartyPopper className="text-4xl text-green-600" />
            </span>
            <a
              className="view-text "
              href={`${BASE_URL}${courseCertificate?.url}`}
              rel="noreferrer"
              target="_blank"
            >
              View Certificate
            </a>
          </div>
        )}
        <div className="w-full bg-white border rounded-lg px-2 mt-4">
          <h2 className="text-2xl text-main mb-4">Course content</h2>
          <table className="w-full border border-collapse border-gray-400">
            <thead className="bg-main">
              <tr role="row" className="text-left">
                <th
                  role="columnheader"
                  scope="col"
                  className="p-2 border border-gray-400"
                >
                  Course name
                </th>
                <th
                  role="columnheader"
                  scope="col"
                  className="p-2 border border-gray-400"
                >
                  Status
                </th>
                <th
                  role="columnheader"
                  scope="col"
                  className="p-2 border border-gray-400"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <CourseContentRow
                courseId={selectedCourse?._id}
                setQuizResult={setQuizResult}
                setFinalQuizResult={setFinalQuizResult}
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CourseModule;
