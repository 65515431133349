import apiSlice from "./apiSlice";

export const questionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQuestionByModuleId: builder.query({
      providesTags: ["question"],
      query: (id) => ({
        url: `api/modules/${id}/questions`,
        method: "GET",
      }),
    }),
    addOrder: builder.mutation({
      invalidatesTags: ["order"],
      query: (body) => ({
        url: "api/order",
        method: "POST",
        body,
      }),
    }),
  }),
});
export const { useAddOrderMutation, useGetQuestionByModuleIdQuery } =
  questionApi;
