import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb";
import ScrollToTop from "../../components/ScrollTop";
import { Link } from "react-router-dom";

// Image
import Logo from "../../assets/images/logos/logo2.png";

const Terms_Condition = () => {
  return (
    <>
      <Header
        parentMenu="contact"
        menuCategoryEnable="enable"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
      />

      <div className="react-wrapper">
        <div className="react-wrapper-inner">
          <Breadcrumb pageTitle="Terms & Conditions" />
          <p className="container text-xl pt-8 px-4">
          Welcome to Workplace Safety. These terms and conditions outline the rules and regulations for the use of Workplace Safety's Website, located at <Link to="https://workplacesafetytraining.net/">workplacesafetytraining.net</Link>.
            </p>
            <p className="container text-xl px-4">By accessing this website, we assume you accept these terms and conditions. Do not continue to use Workplace Safety if you do not agree to all the terms and conditions stated on this page. </p>
        <div className="privacy container bg-gray-100 sm:w-2/3 bg border mb-8">
        <h1 className="pt-8 text-3xl  font-bold">Definitions</h1>
        <p className="text-black mt-2 text-base">
          <li>Client, You, and Your refer to you, the person logging on this website and compliant to the Company’s terms and conditions. </li>
          <li>The Company, Ourselves, We, Our, and Us, refer to our Company. </li>
          <li>Party, Parties, or Us, refer to both the Client and ourselves. </li>
          <li>Courses refer to the workplace safety courses provided on our website. </li>
        </p>
        <h1 className="pt-8 text-3xl  font-bold">
        Usage of the Website         
        </h1>
        <p className="text-black mt-2 text-base">
        By accessing this website, you warrant and represent that:           
          <li>You are at least 18 years old.</li>
          <li>You will use this website in accordance with these terms and conditions.</li>
          <li>You will not use the website for any purpose that is unlawful or prohibited by these terms and conditions.</li>
        </p>
        <h1 className="pt-8 text-3xl  font-bold">
        Courses         
        </h1>
        <p className="text-black mt-2 text-base">
        We offer a variety of workplace safety courses, including but not limited to WHMIS, PPE, and Ladder Safety. The list of all available courses can be found at <Link to="https://workplacesafetytraining.net/courses">workplacesafetytraining.net/courses</Link>.         
        </p>
        <h1 className="pt-8 text-3xl font-bold">Enrollment and Access
        </h1>
        <p className="text-black mt-2 text-base">
          <li>To enroll in a course, you must register on our website and provide accurate and complete information.</li>
          <li>Upon enrollment, you will receive access to the course materials and resources.</li>    
        </p>
        <h1 className="pt-8 text-3xl font-bold">Course Completion</h1>
        <p className="text-black mt-2 text-base">
          <li>Courses are designed to be completed within a specified time frame.</li>
          <li>Upon successful completion of a course, you may receive a certification of completion, subject to the course requirements.</li>
        </p>
        <h1 className="pt-8 text-3xl font-bold">Payment Terms</h1>
        <p className="text-black mt-2 text-base">
        <li>All payments for courses must be made in full at the time of enrollment.</li>
        <li>We accept various payment methods, including credit cards and other secure payment gateways.</li>
        </p>
        <h1 className="pt-8 text-3xl font-bold">Refund Policy </h1>
        <p className="text-black mt-2 text-base">
        <li>Refunds are subject to our discretion and are generally only provided if you are unable to access the course materials due to technical issues caused by us.</li>
        <li>Refund requests must be submitted within a specified period after enrollment.</li>
        </p>
        <h1 className="pt-8 text-3xl font-bold">Intellectual Property </h1>
        <p className="text-black mt-2 text-base">
        <li>Unless otherwise stated, Workplace Safety and/or its licensors own the intellectual property rights for all material on Workplace Safety. All intellectual property rights are reserved.</li>
        <li>You may access this from Workplace Safety for your own personal use subjected to restrictions set in these terms and conditions.</li>
        </p>
        <h1 className="pt-8 text-3xl font-bold">User-Generated Content </h1>
        <p className="text-black mt-2 text-base">
        <li>Certain parts of this website may offer users the opportunity to post and exchange opinions and information. </li>
        <li>Workplace Safety does not filter, edit, publish or review Comments prior to their presence on the website. </li>
        <li>Comments do not reflect the views and opinions of Workplace Safety, its agents, and/or affiliates. </li>
        </p> 
        <h1 className="pt-8 text-3xl font-bold">Limitation of Liability</h1>
        <p className="text-black mt-2 text-base">
        <li>In no event shall Workplace Safety, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website.</li>
        <li>Workplace Safety shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website.</li>
        </p>
        <h1 className="pt-8 text-3xl font-bold">Indemnification </h1>
        <p className="text-black mt-2 text-base">
        <li>You hereby indemnify to the fullest extent Workplace Safety from and against any and all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these terms. </li>
        </p>
        <h1 className="pt-8 text-3xl font-bold">Changes to These Terms </h1>
        <p className="text-black mt-2 text-base">
        <li>We reserve the right to amend or update these terms at any time without prior notice. </li>
        <li>By continuing to use the website, you agree to be bound by the updated terms and conditions. </li>
        </p>
        <h1 className="pt-8 text-3xl font-bold">Contact Us </h1>
        <p className="text-black mt-2 text-base">
        If you have any questions about these Terms and Conditions, please contact us at: 
        <li><strong>Email:</strong> info@workplacesafetytraining.net</li>
        </p>
      </div>
          <ScrollToTop />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Terms_Condition;
