import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useUpdateUserMutation } from "../../Redux/Services/authServices";
import { setUser } from "../../Redux/Slice/authSlice";

const AccountInfo = () => {
  const dispatch = useDispatch();
  const [updateUser] = useUpdateUserMutation();
  const { user } = useSelector((state) => state.auth);
  const [users, setUsers] = useState({
    _id: user?._id,
    name: user?.name || "",
    email: user?.email || "",
    mobileNo: user?.mobileNo || "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobileNo: "",
  });

  const handleChange = (field, value) => {
    setUsers((prevUser) => ({
      ...prevUser,
      [field]: value,
    }));
    // Clear error message when user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formIsValid = validateForm();
    try {
      if (formIsValid) {
        const response = await updateUser(users);
        if (response?.data?.success) {
          dispatch(setUser(response?.data?.data));
          localStorage.setItem(
            "userData",
            JSON.stringify(response?.data?.data)
          );
          toast.success(response?.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const validateForm = () => {
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Basic mobile number validation (10 digits)
    const mobileRegex = /^\d{10}$/;

    let isValid = true;

    if (users.name.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required",
      }));
      isValid = false;
    }

    if (!emailRegex.test(users.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Invalid email address",
      }));
      isValid = false;
    }

    if (!mobileRegex.test(users.mobileNo)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobileNo: "Invalid mobile number",
      }));
      isValid = false;
    }

    return isValid;
  };

  return (
    <>
      <div className="container acc-info">
        <h1>Account Information</h1>
        <form onSubmit={handleSubmit}>
          <div className="acc-lable">
            <label
              htmlFor="name"
              className="block text-gray-600 font-medium mb-2"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={users.name}
              onChange={(e) => handleChange("name", e.target.value)}
              className="acc-input"
              required
            />
            {errors.name && (
              <p className="text-red-500 text-sm">{errors.name}</p>
            )}
          </div>

          <div className="acc-lable">
            <label
              htmlFor="email"
              className="block text-gray-600 font-medium mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={users.email}
              onChange={(e) => handleChange("email", e.target.value)}
              className="acc-input"
              required
            />
            {errors.email && (
              <p className="text-red-500 text-sm">{errors.email}</p>
            )}
          </div>
          <div className="acc-lable">
            <label
              htmlFor="mobileNo"
              className="block text-gray-600 font-medium mb-2"
            >
              Mobile
            </label>
            <input
              type="text"
              id="mobileNo"
              name="mobileNo"
              value={users.mobileNo}
              onChange={(e) => handleChange("mobileNo", e.target.value)}
              className="acc-input"
              required
            />
            {errors.mobileNo && (
              <p className="text-red-500 text-sm">{errors.mobileNo}</p>
            )}
          </div>
          {/* Add more form fields as needed */}
          <button type="submit" className="course-btn">
            Save Changes
          </button>
        </form>
      </div>
    </>
  );
};

export default AccountInfo;
