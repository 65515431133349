import { setAccessToken, setRefreshToken } from "../Redux/Slice/authSlice";

export const singnOut = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("userData");
  window.location.replace("/");
};
export const authMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const token = localStorage.getItem("authToken");
  const refreshToken = localStorage.getItem("refreshToken");

  // if (result.error && result.payload.status === 401) {
  //     store
  //         .dispatch(
  //             // apiSliceType.endpoints.refreshToken.initiate({
  //             //     refreshToken: localStorage.getItem('refreshToken'),
  //             // })
  //         )
  //         .then((res) => {
  //             if (
  //                 res?.error &&
  //                 (res?.error?.status === 401 || res?.error?.status === 500)
  //             ) {
  //                 singnOut()
  //             } else {
  //                 store.dispatch(setAccessToken(res?.data?.data?.accessToken))
  //                 store.dispatch(
  //                     setRefreshToken(res?.data?.data?.refreshToken)
  //                 )
  //                 localStorage.setItem('authToken', res.data?.data?.accessToken)
  //                 localStorage.setItem(
  //                     'refreshToken',
  //                     res.data?.data?.refreshToken
  //                 )
  //             }
  //         })
  // } else if (token && refreshToken && !userData) {
  //     singnOut()

  // }
  return result;
};

export const calculateValidityLeft = (purchasedAt, validity) => {
  const purchasedDate = new Date(purchasedAt);
  const currentDate = new Date();
  const millisecondsPerDay = 1000 * 60 * 60 * 24;

  const timeDifference = currentDate.getTime() - purchasedDate.getTime();
  const daysDifference = Math.floor(timeDifference / millisecondsPerDay);

  const validityLeft = validity - daysDifference;

  return validityLeft ? validityLeft : "Unknown";
};
export const calculateExpirationDate = (purchasedAt, validity) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const purchasedDate = new Date(purchasedAt);
  const expirationDate = new Date(purchasedDate);

  expirationDate.setDate(expirationDate.getDate() + validity);

  const day = expirationDate.getDate();
  const month = months[expirationDate.getMonth()];
  const year = expirationDate.getFullYear();

  return `${day}, ${month} ${year}`;
};
export const formatDuration = (minutes) => {
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = minutes % 60;

  if (hours === 0) {
    return remainingMinutes + " M";
  } else if (remainingMinutes === 0) {
    return hours + " H";
  } else {
    return hours + " H " + remainingMinutes + " M";
  }
};
