import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../Utils/constant";
import { isValidEmail } from "../../Utils/validators";
import { toast } from "react-toastify";
import Header from "../../components/Header/index";
import Breadcrumb from "../../components/Breadcrumb";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    otp: null,
    newPassword: null,
    confirmPassword: null,
  });
  const [emailError, setEmailError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpSentLoading, setOtpSentLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, otp, newPassword, confirmPassword } = data;
    try {
      if (email && otp && newPassword && confirmPassword) {
        if (newPassword === confirmPassword) {
          const response = await axios.put(
            `${BASE_URL}/api/resetPassword`,
            data
          );
          if (response.data.success) {
            setLoading(false);
            toast.success(response.data.message);
            setOtpSent(false);
            navigate("/login");
          } else {
            setLoading(false);

            toast.error(response.data.message);
          }
        } else {
          setLoading(false);
          setConfirmPasswordError("Password not matched");
        }
      } else {
        setLoading(false);

        toast.error("Cannot process empty fields");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error.message);
    }
  };
  const sendForgetPasswordOtp = async () => {
    try {
      if (!isValidEmail(data.email)) {
        setEmailError("Enter valid email");
        return;
      }
      setOtpSentLoading(true);
      const response = await axios.put(`${BASE_URL}/api/sendForgotOtp`, {
        email: data.email,
      });
      if (response.data.success) {
        setEmailError("");
        setOtpSent(true);
        setOtpSentLoading(false);
      }
    } catch (error) {
      setOtpSent(false);
      setOtpSentLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Forgot Password ?" />
      <div>
        <div
          className="text-gray-700 w-full min-h-screen flex justify-center items-center "
          style={{
            padding: "20px",
          }}
        >
          <div className="container mx-auto h-full">
            <div className="w-full flex flex-col justify-center items-center">
              <div className="md:w-4/5 w-full px-5 flex flex-col justify-center items-center">
                {/* <h3 className="bg-secondry px-4 py-2 rounded-xl text-black text-center font-bold tracking-wider md:text-3xl text-2xl">
                  Work Place Safety
                </h3> */}
                <div className="forget-screen md:w-3/4 w-full mx-auto">
                  <p className="text-xl text-center text-black tracking-wider font-bold">
                    Enter your E-mail to get OTP
                  </p>
                  <div className="space-y-6">
                    <input
                      type="email"
                      placeholder="Email"
                      value={data.email}
                      name="email"
                      onChange={(event) =>
                        setData({ ...data, email: event.target.value })
                      }
                      className="w-full px-4 mr-1 py-2 border border-gray-700 rounded-xl bg-transparent focus:outline-none"
                    />
                    {emailError && (
                      <div className="text-red-400 ">{emailError}</div>
                    )}
                    <div className="flex justify-between">
                      <input
                        type="number"
                        placeholder="Otp"
                        name="otp"
                        value={data.otp}
                        maxLength="4"
                        onChange={(event) =>
                          setData({ ...data, otp: event.target.value })
                        }
                        className="w-3/6 px-4 py-2 border border-gray-700 rounded-xl  bg-transparent focus:outline-none"
                      />
                      <button
                        className={` w-1/3 ${
                          otpSent ? "view-btn" : "view-btn"
                        } text-black rounded-xl mr-1`}
                        disabled={otpSent}
                        onClick={() => {
                          sendForgetPasswordOtp();
                        }}
                      >
                        {otpSentLoading
                          ? "Processing..."
                          : otpSent
                          ? "Sent, Check Mail !"
                          : "Send OTP"}
                      </button>
                    </div>
                    <input
                      type="password"
                      placeholder="New Password"
                      name="newPassword"
                      value={data.newPassword}
                      onChange={(event) =>
                        setData({ ...data, newPassword: event.target.value })
                      }
                      className="w-full px-4 py-2 border border-gray-700 rounded-xl  bg-transparent focus:outline-none"
                    />
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      value={data.confirmPassword}
                      onChange={(event) =>
                        setData({
                          ...data,
                          confirmPassword: event.target.value,
                        })
                      }
                      className="w-full px-4 py-2 border border-gray-700 rounded-xl  bg-transparent focus:outline-none"
                    />
                    {confirmPasswordError && (
                      <div className="text-red-400 ">
                        {confirmPasswordError}
                      </div>
                    )}
                    <div className="flex justify-center">
                      <button
                        onClick={(e) => handleForgetPassword(e)}
                        disabled={loading}
                        className="reset-btn font-semibold border w-full py-3 rounded-2xl"
                      >
                        {loading ? "Processing..." : "Reset Password"}
                      </button>
                    </div>
                  </div>
                </div>
                <span className="pt-2">
                  Don't have an Account?{" "}
                  <Link to={"/checkout"} className="underline text-gray-700">
                    SignUp
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
