import React, { useEffect, useState } from "react";
import { useGetOrderByUserIdQuery } from "../../Redux/Services/OrderService";
import { useDispatch, useSelector } from "react-redux";
import { calculateValidityLeft } from "../../Utils";
import { useNavigate } from "react-router";
import {
  useGetInvitedListQuery,
  useInviteUserToCourseMutation,
} from "../../Redux/Services/Courseservice";
import { toast } from "react-toastify";

const Invite = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [course, setCourse] = useState("");
  const [email, setEmail] = useState("");
  const [orderId, setOrderId] = useState("");
  const [emailError, setEmailError] = useState("");
  const { user } = useSelector((state) => state.auth);
  const { data } = useGetOrderByUserIdQuery(user?._id);
  const [inviteUserToCourse] = useInviteUserToCourseMutation();
  const { data: invitedUsers, isLoading } = useGetInvitedListQuery(user?._id);
  console.log(invitedUsers?.data);

  useEffect(() => {
    if (user?.customerType !== "corporate") {
      navigate("/dashboard");
    }
  }, [user]);

  const handleCourseChange = (e) => {
    const selectedOrderId =
      e.target.options[e.target.selectedIndex].getAttribute("data-orderid");
    setOrderId(selectedOrderId);
    setCourse(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleInvite = async () => {
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
      return;
    } else {
      setEmailError("");
    }
    try {
      let body = {
        email: email,
        userId: user?._id,
        courseId: course,
        orderId: orderId,
      };
      const response = await inviteUserToCourse(body);
      if (response?.error) {
        console.log(response.error);
        toast.error(response.error.data.message);
      } else {
        toast.success(response?.data?.message);
        setEmail("");
      }
    } catch (error) {
      console.log(error);
      toast.error("Invitation failed");
    }
  };

  return (
    <>
      <div className="acc-info">
        <h1>Invite User</h1>
        <label
          htmlFor="course"
          className="block text-gray-600 font-medium mb-2"
        >
          Select Course:
        </label>
        <select
          id="course"
          className="acc-input"
          value={course}
          onChange={handleCourseChange}
        >
          <option value="">Select Course</option>
          {data?.data.map((orderItem, orderIndex) => (
            <>
              {orderItem.courseId
                ?.filter(
                  (course, index) =>
                    calculateValidityLeft(
                      orderItem?.courseValidity[index]?.purchasedAt,
                      orderItem?.courseValidity[index]?.validity
                    ) > 0
                )
                .map((course, index) => (
                  <option
                    key={course._id}
                    data-orderid={orderItem._id}
                    value={course._id}
                  >
                    {course.title}
                  </option>
                ))}
            </>
          ))}
        </select>
        <br />
        <label htmlFor="email" className="block text-gray-600 font-medium mb-2">
          Enter Email:
        </label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
          className="acc-input"
        />
        <br />
        {emailError && <p style={{ color: "red" }}>{emailError}</p>}
        <br />
        <button className="course-btn" onClick={handleInvite}>
          Invite
        </button>
      </div>
      <div>
        <h4>Invited Users</h4>
        <ul>
          {invitedUsers?.data?.map((item) => (
            <>
              {item?.InvitedMembers?.filter(
                (ele) => ele?.courseId === course
              ).map((ele, index) => (
                <>
                  <li>{ele?.email}</li>
                </>
              ))}
            </>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Invite;
