// UserDetails.js
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import TopBanner from "../../components/Single Course/TobBanner";
import RelatedCourse from "../../components/Related Course/RelatedCourse";
import {
  setIsTableLoading,
  setSelectedCourse,
} from "../../Redux/Slice/CourseSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetCourseByIdQuery } from "../../Redux/Services/Courseservice";
import Loader from "../../components/Loader/Loader";

function Course() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, isFetching, isLoading } = useGetCourseByIdQuery(id);
  console.log("Course Data", data);
  const { selectedCourse, isTableLoading } = useSelector(
    (state) => state.course
  );
  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setSelectedCourse(data?.data));
    } else {
      dispatch(setIsTableLoading(true));
    }
  }, [dispatch, data, isFetching, isLoading]);

  return (
    <>
      {isLoading ? (
        <Loader /> // Display loader while loading
      ) : (
        <>
          <TopBanner selectedCourse={data?.data} />
          <div className="container mx-auto py-10">
            <h3 className="text-3xl text-center text-secondry">
              Related Course
            </h3>
            <RelatedCourse />
          </div>
        </>
      )}
    </>
  );
}

export default Course;
