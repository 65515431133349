import apiSlice from "./apiSlice";

export const categoryAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      providesTags: ["category"],
      query: (body) => ({
        url: "api/categories/all",
        method: "GET",
        body,
      }),
    }),
  }),
});
export const { useGetCategoriesQuery } = categoryAPI;
