import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BASE_URL } from "../../Utils/constant";
import logo from "../../assets/images/logos/logo.png";
import * as html2pdf from "html2pdf.js";
import { FaPrint } from "react-icons/fa6";

const Invoice = () => {
  const { id: transactionId } = useParams();
  const [InvoiceData, setInvoiceData] = useState({});
  const { userId, orderId, amount, status, sessionId, createdAt } = InvoiceData;

  useEffect(() => {
    const getTrancation = async (req, res) => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/transaction/${transactionId}`
        );
        if (response.data.success) {
          setInvoiceData(response.data.transaction);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getTrancation();
  }, [transactionId]);
  const amountRenderer = (amount, currency) => {
    if (currency === "usd") {
      // If currency is USD, return amount with dollar sign and divided by 100
      return `$${(amount / 100).toFixed(2)}`;
    } else {
      // If currency is not USD, return the amount as is
      return amount;
    }
  };
  const itemTotal = (price, quantity) => {
    let discountedPrice = price;
    let total = 0;

    // Apply discount based on quantity
    if (quantity >= 3 && quantity < 5) {
      discountedPrice = price * 0.98; // 2% discount for 3 or more courses
    } else if (quantity >= 5) {
      discountedPrice = price * 0.95; // 5% discount for 5 or more courses
    }

    total += discountedPrice * quantity;
    return total;
  };
  const itemPrice = (price, quantity) => {
    let newPrice = price;
    if (quantity >= 3) {
      newPrice = price * 0.98;
    } else if (quantity >= 5) {
      newPrice = price * 0.95;
    }
    return newPrice;
  };

  const headStyle = "font-bold text-black text-xl";
  const labelHeadStyle = "text-md font-bold text-gray-600";
  const valueStyle = "text-black font-semibold";

  if (!InvoiceData) {
    return <h1>Loading ...</h1>;
  }
  const generatePDF = () => {
    const element = document.getElementById("invoice-container");
    html2pdf().from(element).save();
  };

  return (
    <div className="flex flex-col items-end p-2">
      <button
        onClick={generatePDF}
        className=" border w-fit bg-main px-2 py-1 flex items-center"
      >
        <FaPrint className="m-1" /> Print
      </button>
      <div
        id="invoice-container"
        className=" bg-white w-full"
        style={{ padding: "4rem", margin: "1rem" }}
      >
        <div>
          <img
            src={logo}
            alt="logo"
            className="mb-8"
            style={{ width: "15rem" }}
          />
        </div>

        <div className="flex flex-col justify-between  w-full ">
          <div className="" style={{ width: "100%" }}>
            <h1 className={`${headStyle}`}>Billing Details</h1>
            <div className="flex">
              <span className="py-4" style={{ width: "25%" }}>
                <h5 className={`${labelHeadStyle}`}>Name</h5>
                <p className={`${valueStyle}`}>{orderId?.name}</p>
              </span>
              <span className="py-4 " style={{ width: "35%" }}>
                <h5 className={`${labelHeadStyle}`}>Email</h5>
                <p className={`${valueStyle}`}>{userId?.email}</p>
              </span>
              <span className="py-4" style={{ width: "40%" }}>
                <h5 className={`${labelHeadStyle}`}>Blling Address</h5>
                <p className={`${valueStyle}`}>
                  {`${orderId?.streetNo} ${orderId?.streetName} , ${orderId?.townCity},${orderId?.province}`}
                </p>
              </span>
            </div>
          </div>

          <div className="" style={{ width: "100%" }}>
            <div className="flex flex-col h-full ">
              <div className="">
                <h1 className={`${headStyle}`}>Reciept</h1>
                <div className="flex w-full ">
                  <div className="pb-4" style={{ width: "70%" }}>
                    <h5 className={`${labelHeadStyle}`}>Order Id</h5>
                    <p className={`${valueStyle}`}>{sessionId?.slice(0, 20)}</p>
                  </div>
                  <div className="pb-4" style={{ width: "30%" }}>
                    <h5 className={`${labelHeadStyle}`}>Date</h5>
                    <p className={`${valueStyle}`}>
                      {new Date(createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>

              <div className="">
                <h1 className={`${headStyle}`}>Payment Information</h1>
                <div className="flex w-full ">
                  <div className="" style={{ width: "60%" }}>
                    <div className={`${labelHeadStyle}`}>Payment Status</div>
                    <div
                      className={`max-w-fit  capitalize  rounded
                      ${
                        status === "success"
                          ? "text-green-600"
                          : status === "failed"
                          ? "text-red-600"
                          : ""
                      }
                      `}
                    >
                      {status ? status : "N/A"}
                    </div>
                  </div>
                  <div className="" style={{ width: "40%" }}>
                    <h5 className={`${labelHeadStyle}`}>Method</h5>
                    <p className={`${valueStyle}`}>By Card</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class=" bg-white rounded-lg ">
            <div class="bg-gray-300 px-2 py-4">
              <div class="flex justify-between font-semibold">
                <div>#</div>
                <div>Product</div>
                <div class="w-20">Quantity</div>
                <div class="w-20">Price</div>
                <div class="w-24">Amount </div>
              </div>
            </div>
            <div class="">
              {orderId?.courseId &&
                orderId?.courseId?.map((course, index) => (
                  <div class="flex border-b-2 justify-between items-center p-2">
                    <div className="w-6">{index + 1}</div>
                    <div className="w-20 ">
                      {course.title.length > 20
                        ? course.title.substring(0, 20) + "..."
                        : course.title}
                    </div>
                    <div class="w-20">
                      {orderId?.courseQuantity[index]?.quantity}
                    </div>
                    <div class="w-20">
                      {`$${itemPrice(
                        course.price,
                        orderId?.courseQuantity[index]?.quantity
                      )}`}
                    </div>
                    <div class="w-24">{`$${itemTotal(
                      course?.price,
                      orderId?.courseQuantity[index]?.quantity
                    )}`}</div>
                  </div>
                ))}
            </div>
            <div class="py-1">
              <div class="flex justify-end my-1">
                <div class="w-56"><strong>SubTotal</strong></div>
                <div class="w-24">${`${
                  amount ? amountRenderer(amount, "usd") : 0
                }`}</div>
              </div>
              <div class="flex justify-end my-1">
                <div class="w-56"><strong>Tax</strong></div>
                <div class="w-24">$0</div>
              </div>
              <div class="flex justify-end my-2 border-t-2 ">
                <div class="w-56 font-bold">Total</div>
                <div class="w-24 font-bold">{`${
                  amount ? amountRenderer(amount, "usd") : 0
                }`}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="border-t-2 mt-[4rem]"></div>
        <div className="flex flex-col items-center font-bold mt-4">
          <div className="text-slate-400">
            If you have any questions, contact Global Safety Training at:
          </div>
          <div>contact@globalsafety.com</div>
          <div>+81 852 96325</div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
