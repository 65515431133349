import React from 'react';

const Table = () => {
  // Sample data for the table
  const data = [
    { id: 1, name: 'John', age: 30 },
    { id: 2, name: 'Jane', age: 25 },
    { id: 3, name: 'Doe', age: 35 }
  ];

  return (
    <div className='cart-main w-full'>
      <h4>My Cart</h4>
      <table>
        <thead>
          <tr>
            <th >ID</th>
            <th>Name</th>
            <th>Age</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.age}</td>
            </tr>
          ))}
        </tbody>
      </table>
      
    </div>
  );
};

export default Table;
