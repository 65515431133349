import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb";
import ScrollToTop from "../../components/ScrollTop";

// Image
import Logo from "../../assets/images/logos/logo2.png";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <Header
        parentMenu="contact"
        menuCategoryEnable="enable"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
      />

      <div className="react-wrapper">
        <div className="react-wrapper-inner">
          <Breadcrumb pageTitle="Privacy Policy" />
          <p className="container text-xl pt-8 px-4">Welcome to Workplace Safety ("we," "us," "our"). We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <Link to="https://workplacesafetytraining.net/">workplacesafetytraining.net</Link> and use our services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site. </p>
        <div className="privacy container px-4 bg-gray-100 sm:w-2/3 bg border mb-8">
        <h1 className="pt-8 text-3xl  font-bold">Information We Collect</h1>
        <p className="text-black mt-2 text-base">
        We may collect information about you in a variety of ways. The information we may collect on the Site includes: 
        </p>
        <div className="px-4 lg:px-20 subhead">
        <h1 className="pt-8 text-2xl font-bold">Personal Data</h1>
        <p className="text-black mt-2 text-base">
        Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as online chat and message boards. 
        </p>
        <h1 className="pt-8 text-2xl font-bold">
        Derivative Data         
        </h1>
        <p className="text-black mt-2 text-base">
        Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.
        </p>
        <h1 className="pt-8 text-2xl font-bold">
        Financial Data         
        </h1>
        <p className="text-black mt-2 text-base">
        Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site. 
          {/* The CCA may use personal information for the following purposes:
          <li>To process memberships</li>
          <li>To respond to inquiries and requests</li>
          <li>For internal record-keeping</li>
          <li>To improve our services</li> */}
        </p>
        <h1 className="pt-8 text-2xl  font-bold">
        Mobile Device Data         
        </h1>
        <p className="text-black mt-2 text-base">
        Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the Site from a mobile device. 
        </p>
        <h1 className="pt-8 text-2xl  font-bold">
        Third-Party Data         
        </h1>
        <p className="text-black mt-2 text-base">
        Information from third parties, such as personal information or network friends, if you connect your account to the third party and grant the Site permission to access this information. 
        </p>
        <h1 className="pt-8 text-2xl font-bold">
        Data From Contests, Giveaways, and Surveys        
        </h1>
        <p className="text-black mt-2 text-base">
        Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.
        </p>
        </div>
        <h1 className="pt-8 text-3xl  font-bold">
        Use of Your Information         
        </h1>
        <p className="text-black mt-2 text-base">
        Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:
          <li>Create and manage your account. </li>
          <li>Process your transactions.</li>
          <li>Email you regarding your account or order.</li>
          <li>Fulfil and manage purchases, orders, payments, and other transactions related to the Site.</li>
          <li>Improve our services and develop new products.</li>
          <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
          <li>Analyse usage trends to improve our services.</li>
          <li>Offer new products, services, and/or recommendations to you.</li>
          <li>Perform other business activities as needed.</li>
        </p>
        <h1 className="pt-8 text-3xl  font-bold">
        Disclosure of Your Information         
        </h1>
        <p className="text-black mt-2 text-base">
        We may share information we have collected about you in certain situations. Your information may be disclosed as follows: 
        </p>
        <div className="px-4 lg:px-20 subhead">
        <h1 className="pt-8 text-2xl font-bold">By Law or to Protect Rights</h1>
        <p className="text-black mt-2 text-base">
        If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.         </p>
        <h1 className="pt-8 text-2xl font-bold">
        Third-Party Service Providers         
        </h1>
        <p className="text-black mt-2 text-base">
        We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.         
        </p>
        <h1 className="pt-8 text-2xl font-bold">
        Business Transfers        
        </h1>
        <p className="text-black mt-2 text-base">
        We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.          {/* The CCA may use personal information for the following purposes:
          <li>To process memberships</li>
          <li>To respond to inquiries and requests</li>
          <li>For internal record-keeping</li>
          <li>To improve our services</li> */}
        </p>
        <h1 className="pt-8 text-2xl  font-bold">
        Marketing Communications        
        </h1>
        <p className="text-black mt-2 text-base">
        With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law. 
        </p>
        <h1 className="pt-8 text-2xl  font-bold">
        Interactions with Other Users         
        </h1>
        <p className="text-black mt-2 text-base">
        If you interact with other users of the Site, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, liking posts, and following blogs. 
        </p>
        </div>
        <h1 className="pt-8 text-3xl font-bold">Security of Your Information </h1>
        <p className="text-black mt-2 text-base">
        We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
        </p>
        <h1 className="pt-8 text-3xl font-bold">Policy for Children </h1>
        <p className="text-black mt-2 text-base">
        We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you become aware of any data, we have collected from children under age 13, please contact us at [Contact Information]. 
        </p>
        <h1 className="pt-8 text-3xl font-bold">Options Regarding Your Information </h1>
        <p className="text-black mt-2 text-base">
        You may at any time review or change the information in your account or terminate your account by:
        <li>Logging into your account settings and updating your account. </li>
        <li>Contacting us using the contact information provided below.</li>
        </p>
        <p className="text-black mt-2 text-base">
        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements. 
        </p>
      
      </div>
          <ScrollToTop />
          {/* scrolltop-end */}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
