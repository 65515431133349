import React, { useState, useEffect } from "react";
import Header from "../Header/index";
import SideNavLayout from "./SideNavLayout";
import Header2 from "../Header/Header2";

function MainLayout({ children }) {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const checkIsDesktop = () => {
      setIsDesktop(window.innerWidth >= 768); // Adjust the threshold as needed
    };

    // Initial check on component mount
    checkIsDesktop();

    // Event listener to check for window resize
    window.addEventListener("resize", checkIsDesktop);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", checkIsDesktop);
    };
  }, []);

  return (
    <div className="flex flex-col h-full overflow-hidden">
      {isDesktop ? (
        <>
          <Header/>
        <div className="flex flex-row h-full p-4">
          <div className="lg:w-1/3">
            <SideNavLayout className="h-screen" />
          </div>

          <div className="w-full min-h-screen md:pl-8">{children}</div>
        </div>
        </>
      ) : (
        <div className="h-full">
          <Header2 />

          <div className="w-full min-h-screen">{children}</div>
        </div>
      )}
    </div>
  );
}

export default MainLayout;
