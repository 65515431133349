import React, { useEffect, useState } from "react";
import {
  SideNavLayoutData,
  // SideNavLayoutData as sideLinks,
} from "../../components/Utils/SideNavLayoutData";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logos/logo.png";
import { useLogoutMutation } from "../../Redux/Services/authServices";
import { setUser } from "../../Redux/Slice/authSlice";
import { useDispatch } from "react-redux";

const SideNavLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const sideLinks = SideNavLayoutData(userData?.customerType);

  const handleLogout = async () => {
    try {
      dispatch(setUser(null));
      await logout();

      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userData");
      navigate("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div>
      <div className={`side-nav`}>
        {sideLinks.map((sideLink, i) => {
          if (sideLink.title === "Logout") {
            return (
              <div
                key={i}
                className="side-title"
                onClick={handleLogout}
                style={{ cursor: "pointer" }}
              >
                <div className="side-icon">{sideLink.icons}</div>
                <span className="link-text">{sideLink.title}</span>
              </div>
            );
          } else {
            return (
              <Link className="dash-link" to={sideLink.link} key={i}>
                <div className="sidelink">
                  <div className="side-icon">{sideLink.icons}</div>
                  <span className="link-text">{sideLink.title}</span>
                </div>
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SideNavLayout;
