import apiSlice from "./apiSlice";

export const paymentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserTransactions: builder.query({
      providesTags: ["transactions"],
      query: (id) => ({
        url: `api/userTransactions/${id}`,
        method: "GET",
      }),
    }),
    addPayemnt: builder.mutation({
      invalidatesTags: ["[payment]"],
      query: (body) => ({
        url: "api/create-checkout-session",
        method: "POST",
        body,
      }),
    }),
  }),
});
export const { useAddPayemntMutation, useGetUserTransactionsQuery } =
  paymentApi;
