import React, { useState } from "react";
import { BASE_URL } from "../../Utils/constant";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      formErrors.name = "Name is required";
      isValid = false;
    }

    if (!formData.email) {
      formErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.subject.trim()) {
      formErrors.subject = "Subject is required";
      isValid = false;
    }

    if (!formData.phone) {
      formErrors.phone = "Phone is required";
      isValid = false;
    } else if (!/^\d{10,12}$/.test(formData.phone)) {
      formErrors.phone = "Phone is invalid (should be 10-12 digits)";
      isValid = false;
    }

    if (!formData.message.trim()) {
      formErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch(`${BASE_URL}/api/send-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        if (response.ok) {
          alert("Message sent successfully!");
          setFormData({
            name: "",
            email: "",
            subject: "",
            phone: "",
            message: "",
          });
          setErrors({});
        } else {
          alert("Failed to send message. Please try again.");
        }
      } catch (error) {
        console.error("Error sending message:", error);
        alert("Failed to send message. Please try again.");
      }
    }
  };

  return (
    <div className="react-blog-form">
      <h2 className="contact-title">
        Questions? <br /> Feel free to contact us.
      </h2>
      <div id="blog-form" className="blog-form">
        <div id="form-messages"></div>
        <form id="contact-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6">
              <div className="back-input">
                <input
                  id="name"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="Name"
                />
                {errors.name && <p className="error">{errors.name}</p>}
              </div>
            </div>

            <div className="col-lg-6 pdl-5">
              <div className="back-input">
                <input
                  id="email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Email"
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="back-input">
                <input
                  id="subject"
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  placeholder="Subject"
                />
                {errors.subject && <p className="error">{errors.subject}</p>}
              </div>
            </div>

            <div className="col-lg-6 pdl-5">
              <div className="back-input">
                <input
                  id="phone"
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  placeholder="Phone"
                />
                {errors.phone && <p className="error">{errors.phone}</p>}
              </div>
            </div>

            <div className="col-lg-12">
              <div className="back-textarea">
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  placeholder="Message"
                ></textarea>
                {errors.message && <p className="error">{errors.message}</p>}
              </div>
            </div>

            <div className="col-lg-12">
              <button type="submit" className="back-btn">
                Send Message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
