import { Link } from "react-router-dom";

// Image
import aboutImg from "../../assets/images/about/ab.png";
import aboutBadge from "../../assets/images/about/badge.png";

const About = () => {
  return (
    <div className="about__area about__area_one p-relative pt---10 pb---120">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="about__image">
              <img src={aboutImg} alt="About" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about__content">
              <h2
                className="about__title wow animate__fadeInUp"
                data-wow-duration="0.3s"
              >
                Welcome to <br /> <em>Work Place Safety Training Portal</em>
              </h2>
              <p
                className="about__paragraph wow animate__fadeInUp"
                data-wow-duration="0.5s"
              >
                Online training provides the flexibility to learn at one’s own
                pace and schedule, <br /> making it ideal for busy
                professionals.
              </p>
              <p
                className="about__paragraph2 wow animate__fadeInUp"
                data-wow-duration="0.7s"
              >
                {" "}
                Have questions? <Link to="#"> Get Free Guide </Link>
              </p>
              <p className="wow animate__fadeInUp" data-wow-duration="0.6s">
                Why Choose us? <br />
                1. Comprehensive Course Offerings
                <br />
                2. Flexibility and Accessibility <br />
                3. Interactive Learning Experiences <br />
                4. Certification and Compliance
                <br />
                5. Cost-Effectiveness{" "}
              </p>
              <ul className="wow animate__fadeInUp" data-wow-duration="0.9s">
                <li>
                  <Link to="/about" className="more-about">
                    {" "}
                    Read More{" "}
                    
                  </Link>
                </li>
                <li className="last-li">
                  <em>Get Support</em>
                  <Link to="mailto:info@workplacesafetytraining.net">
                    info@workplacesafety
                    <br />
                    training.net
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
