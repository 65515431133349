import { MdDashboardCustomize } from "react-icons/md";
import { TbBrandBooking } from "react-icons/tb";
import { CgPlayListAdd } from "react-icons/cg";

export const SideNavLayoutData = (userType) => {
  const commonLinks = [
    {
      title: "Dashboard",
      icons: <MdDashboardCustomize />,
      link: "/dashboard",
    },
    {
      title: "Receipts",
      icons: <CgPlayListAdd />,
      link: "/dashboard/receipt",
    },
    {
      title: "Account Info",
      icons: <TbBrandBooking />,
      link: "/dashboard/accountinfo",
    },
    {
      title: "Contact Us",
      icons: <TbBrandBooking />,
      link: "/contact",
    },
    {
      title: "Logout",
      icons: <TbBrandBooking />,
      link: "/login",
    },
  ];

  if (userType === "corporate") {
    commonLinks.splice(2, 0, {
      title: "Invite",
      icons: <TbBrandBooking />,
      link: "/invite",
    });
  }

  return commonLinks;
};
