import React, { useState, useRef } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  useAddNewUserMutation,
  useVerifyRecaptchaMutation,
} from "../../Redux/Services/authServices";
import { isValidEmail, isValidMobileNumber } from "../../Utils/validators";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const SignupMain = () => {
  const recaptcha = useRef();
  const [verifyRecaptcha] = useVerifyRecaptchaMutation();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [termsError, setTermsError] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
    mobileNo: "",
    name: "",
    customerType: "normal",
  });

  const [visible, setVisible] = useState(false);
  const [addNewUser] = useAddNewUserMutation();
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setEmailError("");
    setPasswordError("");
    setMobileNumberError("");
    setUsernameError("");
    setError("");
    setTermsError("");
    const captchaValue = recaptcha.current.getValue();

    if (!data.email) {
      setEmailError("Email is required");
      setVisible(true);
      return;
    }
    if (!isValidEmail(data.email)) {
      setEmailError("Invalid Email");
      setVisible(true);
      return;
    }

    if (!data.password) {
      setPasswordError("Password is required");
      setVisible(true);
      return;
    }
    if (!data.mobileNo) {
      setMobileNumberError("Mobile number is required");
      setVisible(true);
      return;
    }

    if (!isValidMobileNumber(data.mobileNo)) {
      setMobileNumberError("Enter Valid Mobile Number");
      setVisible(true);
      return;
    }
    if (!data.name) {
      setUsernameError("Username is required");
      setVisible(true);
      return;
    }
    if (!document.getElementById("terms-conditions").checked) {
      setTermsError("Please accept terms & conditions");
      return;
    }

    if (!captchaValue) {
      toast.info("Please verify reCAPTCHA!");
    } else {
      try {
        const recaptchaResponse = await verifyRecaptcha({
          captchaValue: captchaValue,
        });

        if (recaptchaResponse?.data?.success) {
          setLoading(true);

          const res = await addNewUser({
            email: data.email,
            password: data.password,
            mobileNo: data.mobileNo,
            name: data.name,
            customerType: data.customerType,
          });
          if (res.data && res.data.status === "ok") {
            setLoading(false);
            handleSuccessfulLogin();
          } else {
            setLoading(false);
            toast.error(res?.data?.msg);
          }
        } else {
          console.log(recaptchaResponse);
          recaptcha.current.reset();

          toast.error("failed to Verify captcha");
        }
      } catch (err) {
        setLoading(false);

        console.error(err);
        toast.error("An error occurred during signup. Please try again.");
      }
    }
  };

  const handleSuccessfulLogin = () => {
    const redirectUrl = localStorage.getItem("checkoutRedirectUrl");

    if (redirectUrl) {
      setError("Log in to register and attend the event.");

      localStorage.removeItem("checkoutRedirectUrl");

      navigate(redirectUrl);
    } else {
      toast.success("Registered Sucessfully");
      setTimeout(() => {
        navigate("/login");
      }, 6000);
    }
  };

  return (
    <>
      <div className="react-login-page react-signup-page pt---120 pb---120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-right-form">
                <form onSubmit={handleSignup}>
                  <div className="login-top">
                    <h3>Sign Up</h3>
                    <p>
                      Already have an account?{" "}
                      <Link className="maincolor" to={`/login`}>
                        <strong>Log in</strong>
                      </Link>
                    </p>
                    {visible && emailError && (
                      <div className="text-danger">{emailError}</div>
                    )}
                    {passwordError && (
                      <div className="text-danger">{passwordError}</div>
                    )}
                    {mobileNumberError && (
                      <div className="text-danger">{mobileNumberError}</div>
                    )}
                    {usernameError && (
                      <div className="text-danger">{usernameError}</div>
                    )}
                  </div>
                  <p>
                    <label>Customer Type</label>
                    <select
                      id="customerType"
                      name="customerType"
                      defaultValue={"individual"}
                      onChange={(event) =>
                        setData({ ...data, customerType: event.target.value })
                      }
                    >
                      <option value="individual">Individual</option>
                      <option value="corporate">Corporate</option>
                    </select>
                  </p>
                  <p>
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={data.email}
                      onChange={(event) =>
                        setData({ ...data, email: event.target.value })
                      }
                    />
                  </p>
                  <p>
                    <label>Name</label>
                    <input
                      placeholder="Name"
                      type="text"
                      id="name"
                      name="name"
                      onChange={(event) =>
                        setData({ ...data, name: event.target.value })
                      }
                    />
                  </p>
                  <p>
                    <label>Mobile</label>
                    <input
                      placeholder="Mobile"
                      type="tel"
                      id="mobile"
                      name="mobileNo"
                      onChange={(event) =>
                        setData({ ...data, mobileNo: event.target.value })
                      }
                    />
                  </p>
                  <p>
                    <label>Password</label>
                    <input
                      placeholder="Password"
                      type="password"
                      id="password"
                      name="password"
                      onChange={(event) =>
                        setData({ ...data, password: event.target.value })
                      }
                    />
                  </p>

                  <div className="back-check-box">
                    <input type="checkbox" id="terms-conditions" /> I agree to
                    the <em>Terms & Conditions</em>
                    <p></p>
                  </div>
                  <div className="flex justify-center mt-2 mb-4">
                    <ReCAPTCHA
                      ref={recaptcha}
                      sitekey={process.env.REACT_APP_SITE_KEY}
                    />
                  </div>
                  {termsError && (
                    <div className="text-danger">{termsError}</div>
                  )}
                  <button type="submit" id="button" name="submit">
                    {loading ? "Registering..." : "Register"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupMain;
