import { Slice, createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: [],
    isTableLoading: false,
  },
  reducers: {
    setCart: (state, action) => {
      const newItem = action.payload;
      console.log(newItem);

      const existingItemIndex = state.cart.findIndex(
        (item) => item?.courses?._id === newItem?.courses?._id
      );

      if (existingItemIndex !== -1) {
        state.cart[existingItemIndex].quantity += newItem?.quantity;
      } else {
        state.cart = [...state?.cart, newItem];
      }
    },
    removeItemFromCart: (state, action) => {
      const itemId = action.payload;
      state.cart = state.cart.filter((item) => item?.courses?._id !== itemId);
    },
    decreaseItemQuantity: (state, action) => {
      const itemId = action.payload;
      const item = state.cart.find((item) => item?.courses?._id === itemId);

      if (item) {
        item.quantity -= 1;
      }
    },
    increaseItemQuantity: (state, action) => {
      const itemId = action.payload;
      const item = state.cart.find((item) => item?.courses?._id === itemId);

      if (item) {
        item.quantity += 1;
      }
    },
    setIsTableLoading: (state, action) => {
      state.isTableLoading = action.payload;
    },
    clearCart: (state) => {
      state.cart = [];
    },
  },
});
export const {
  setCart,
  setIsTableLoading,
  removeItemFromCart,
  clearCart,
  decreaseItemQuantity,
  increaseItemQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;
