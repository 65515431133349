import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import FinalQuiz from "../../components/Single Course/Quiz/FinalQuiz";
import { useGetFinalQuizOfCourseQuery } from "../../Redux/Services/Courseservice";

const FinalQuizModule = ({ id }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const quizCourseId = queryParams.get("courseId");
  const [quizData, setQuizData] = useState([]);
  const { data, isLoading } = useGetFinalQuizOfCourseQuery(quizCourseId);
  console.log(data);
  useEffect(() => {
    if (!isLoading) {
      setQuizData(data?.data);
    }
  }, [quizCourseId, isLoading]);

  return (
    <>
      {isLoading ? (
        <p>Loading ...</p>
      ) : (
        <FinalQuiz questions={quizData} quizCourseId={quizCourseId}  />
      )}
    </>
  );
};

export default FinalQuizModule;
