import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
// import { BASE_URL } from "../../../Utils/constant";
const BASE_URL = "https://api.workplacesafetytraining.net";

const VideoPlayer = ({
  videoUrl,
  onPrevious,
  onNext,
  handleVideoEnd,
  handleVideoChange,
  handleLoadedMetadata,
  videoRef,
}) => {
  if (!videoUrl) {
    return null;
  }

  return (
    <div>
      <video
        ref={videoRef}
        width="100%"
        height="360"
        controls
        autoPlay
        onEnded={handleVideoEnd}
        onLoadedMetadata={handleLoadedMetadata}
      >
        <source src={`${BASE_URL}${videoUrl}`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="py-2 pr-2 gap-2 flex justify-end ">
        <button
          // className=" text-gray-700 py-2 px-3 rounded-lg mr-2"
          className="view-text"
          onClick={onPrevious}
        >
          Previous
          {/* <FontAwesomeIcon icon={faChevronLeft} /> */}
        </button>
        <button
          className="view-text"
          // className=" text-gray-700 px-3 py-2 rounded-lg"
          onClick={onNext}
        >
          Next
          {/* <FontAwesomeIcon icon={faChevronRight} /> */}
        </button>
      </div>
    </div>
  );
};

export default VideoPlayer;
