import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetUserTransactionsQuery } from "../../Redux/Services/PaymentService";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { FaEye } from "react-icons/fa";
import { MdLaunch } from "react-icons/md";
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; 
import { useMediaQuery } from 'react-responsive';


const formatDate = (createdAt) => {
  const date = new Date(createdAt);

  // Get day, month, and year
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" }); // Get abbreviated month name
  const year = date.getFullYear();
  
  // Concatenate day, month, and year with dashes
  return `${day}-${month}-${year}`;
};
const amountRenderer = (amount, currency) => {
  if (currency === "usd") {
    // If currency is USD, return amount with dollar sign and divided by 100
    return `$${(amount / 100).toFixed(2)}`;
  } else {
    // If currency is not USD, return the amount as is
    return amount;
  }
};

const Receipt = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { user } = useSelector((state) => state.auth);
  const { data: userTransactions, isLoading: UTisLoading } =
    useGetUserTransactionsQuery(user?._id);

  return (
    <div className="px-2">
      <div className="transaction">
        <div className="transaction-title flex flex-row md:flex-row items-center justify-between">
          <h4 className="text-sm md:text-xl font-semibold -mb-1">Transaction History</h4>
          <div className="trans-btn">
            <Link to={"/dashboard/receipt"} className="view-btn">
              {" "}
              View All Receipt
            </Link>
          </div>
        </div>
        <div className="trans-grid">
          <h1 className="col-span-3">ID</h1>
          <h1 className="col-span-3 sm:col-span-3">Date</h1>
          <h1 className="col-span-2 sm:col-span-2">Status</h1>
          <h1 className="col-span-2">Total</h1>
          <h1 className="col-span-2 sm:col-span-2">Receipt</h1>
        </div>
        <div className=" ">
          {UTisLoading ? (
            <div className="m-2">
              <Loader />
            </div>
          ) : (
            <>
              {userTransactions?.transactions?.length === 0 && (
                <div className="no-course">
                  <h3>No Transaction Found</h3>
                </div>
              )}
              {userTransactions?.transactions?.length !== 0 &&
                userTransactions?.transactions
                  ?.slice()
                  ?.reverse()
                  ?.slice(0, 2)
                  ?.map(
                    ({
                      sessionId,
                      amount,
                      currency,
                      status,
                      createdAt,
                      _id,
                    }) => (
                      <div className="trans-grid1">
                        <h1 className="sessionId">{sessionId.slice(0, 8)}</h1>

                        <div className="trans-date">
                          <h1 className="trans-lable">
                            {formatDate(createdAt)}
                          </h1>
                        </div>
                        <div className="flex items-center col-span-2 sm:col-span-2 ">
                          {isMobile ? (
                            // Mobile view, render icons instead of text
                            <div className={`${
                              status === "success"
                                ? "text-green-500"
                                : status === "failed"
                                ? "text-red-500"
                                : ""
                            } trans-icon`}>
                              {status === "success" ? <FaCheckCircle /> : <FaTimesCircle />}
                            </div>
                          ) : (
                            // Desktop view, render status text
                            <h1
                              className={`${
                                status === "success"
                                  ? "trans-lable-success"
                                  : status === "failed"
                                  ? "trans-lable-fail"
                                  : ""
                              } trans-lable capitalize`}
                            >
                              {status}
                            </h1>
                          )}
                        </div>

                        <div className="trans-amt">
                          <h1>{amountRenderer(amount, currency)}</h1>
                        </div>

                        <div className="invoice">
                          <button
                            onClick={() => {
                              navigate(`/dashboard/invoice/${_id}`);
                            }}
                            className="launch"
                          >
                            {isMobile ? (
                              // Mobile view, render icon instead of text
                              <FaEye />
                            ) : (
                              // Desktop view, render text
                              <>
                            
                                View Receipt
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    )
                  )}
            </>
          )}
        </div>
        <div className="no-course">
          {/* <h1 className="text-gray-300 font-medium col-span-2">
            Showing 1 courses
          </h1> */}
        </div>
      </div>
    </div>
  );
};

export default Receipt;
