import React, { useRef, useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import { useNavigate, useParams } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import {
  useLoginMutation,
  useAddNewUserMutation,
  useVerifyRecaptchaMutation,
} from "../../Redux/Services/authServices";
import { toast } from "react-toastify";
import {
  setAccessToken,
  setRefreshToken,
  setUser,
} from "../../Redux/Slice/authSlice";
import axios from "axios";
import { BASE_URL } from "../../Utils/constant";
import { useGetCourseByIdQuery } from "../../Redux/Services/Courseservice";

const Invited = () => {
  const params = useParams();
  const { email, courseId, orderId } = params;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptcha = useRef();
  const [login] = useLoginMutation();
  const [addNewUser] = useAddNewUserMutation();
  const [verifyRecaptcha] = useVerifyRecaptchaMutation();
  const { user } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({ email: email });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [fieldValidations, setFieldValidations] = useState({
    firstName: {
      value: "",
      isValid: true,
      errorMessage: "Please fill Required filled",
    },
    lastName: {
      value: "",
      isValid: true,
      errorMessage: "Please fill Required filled",
    },
    mobileNo: { value: "", isValid: true, errorMessage: "" },
    email: { value: "", isValid: true, errorMessage: "" },
    password: { value: "", isValid: true, errorMessage: "" },
    streetName: { value: "", isValid: true, errorMessage: "" },
    // streetNo: { value: "", isValid: true, errorMessage: "" },
    postalCode: { value: "", isValid: true, errorMessage: "" },
    townCity: { value: "", isValid: true, errorMessage: "" },
    country: { value: "", isValid: true, errorMessage: "" },

    province: { value: "", isValid: true, errorMessage: "" },
  });
  const handleInputChange = (fieldName) => (event) => {
    const inputValue = event.target.value.trim();
    setFormData((prev) => ({
      ...prev,
      [fieldName]: inputValue,
    }));

    setFieldValidations((prevValidations) => ({
      ...prevValidations,
      [fieldName]: {
        value: inputValue,
        isValid: inputValue !== "",
        errorMessage: inputValue !== "" ? "" : "Field is required.",
      },
    }));

    // Other field validations
  };

  const handleCountryChangeoption = (val) => {
    setFieldValidations((prevValidations) => ({
      ...prevValidations,
      country: {
        value: val,
        isValid: val !== "",
        errorMessage: val !== "" ? "" : "Field is required.",
      },
    }));
    setFormData((prev) => ({
      ...prev,
      country: val,
    }));
  };

  const handleRegionChangeption = (val) => {
    setFieldValidations((prevValidations) => ({
      ...prevValidations,
      province: {
        value: val,
        isValid: val !== "",
        errorMessage: val !== "" ? "" : "Field is required.",
      },
    }));
    setFormData((prev) => ({
      ...prev,
      province: val,
    }));
  };

  const handlePlaceOrder = async (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();

    if (!captchaValue) {
      toast.info("Please verify reCAPTCHA!");
      return;
    }

    try {
      const recaptchaResponse = await verifyRecaptcha({
        captchaValue: captchaValue,
      });
      setIsButtonClicked(true);
      const validations = Object.keys(fieldValidations).reduce(
        (result, fieldName) => {
          const inputValue = fieldValidations[fieldName].value.trim();
          // Skip email and password validation if the user exists
          if (fieldName === "email" && user) {
            result[fieldName] = {
              value: inputValue,
              isValid: true,
              errorMessage: "",
            };
          } else if (fieldName === "password" && user) {
            result[fieldName] = {
              value: inputValue,
              isValid: true,
              errorMessage: "",
            };
          } else {
            // Perform specific validations based on field name
            switch (fieldName) {
              case "firstName":
                result[fieldName] = {
                  value: inputValue,
                  isValid: /^[A-Za-z\s]+$/.test(inputValue), // Name should contain only letters and spaces
                  errorMessage: /^[A-Za-z\s]+$/.test(inputValue)
                    ? ""
                    : "Invalid first Name format.",
                };
                break;
              case "lastName":
                result[fieldName] = {
                  value: inputValue,
                  isValid: /^[A-Za-z\s]+$/.test(inputValue), // Name should contain only letters and spaces
                  errorMessage: /^[A-Za-z\s]+$/.test(inputValue)
                    ? ""
                    : "Invalid last Name format.",
                };
                break;
              case "email":
                if (user) {
                  break;
                }

                break;
              case "mobileNo":
                result[fieldName] = {
                  value: inputValue,
                  isValid: /^\d{10}$/.test(inputValue), // Mobile number should be 10 digits
                  errorMessage: /^\d{10}$/.test(inputValue)
                    ? ""
                    : "Invalid mobile number.",
                };
                break;
              case "townCity":
                result[fieldName] = {
                  value: inputValue,
                  isValid: /^[A-Za-z\s]+$/.test(inputValue), // Town/City should contain only letters and spaces
                  errorMessage: /^[A-Za-z\s]+$/.test(inputValue)
                    ? ""
                    : "Invalid town/city name format.",
                };
                break;
              case "postalCode":
                result[fieldName] = {
                  value: inputValue,
                  isValid: /^[A-Za-z0-9\s]+$/.test(inputValue), // Postal code can contain letters, numbers, and spaces
                  errorMessage: /^[A-Za-z0-9\s]+$/.test(inputValue)
                    ? ""
                    : "Invalid postal code format.",
                };
                break;
              case "password":
                if (user) {
                  break;
                }
                result[fieldName] = {
                  value: inputValue,
                  isValid: inputValue.length >= 8, // Password should be minimum 8 characters long
                  errorMessage:
                    inputValue.length >= 8
                      ? ""
                      : "Password must be at least 8 characters long.",
                };
                break;
              default:
                result[fieldName] = {
                  value: inputValue,
                  isValid: inputValue !== "",
                  errorMessage: inputValue !== "" ? "" : "Field is required.",
                };
                break;
            }
          }

          return result;
        },
        {}
      );
      setFieldValidations(validations);

      const areAllFieldValid = Object.values(validations).every(
        (validation) => validation.isValid
      );

      if (!areAllFieldValid) {
        return;
      }
      if (recaptchaResponse?.data?.success) {
        let userId = user?._id;

        if (!user) {
          const firstNameOrder = formData.firstName;
          const LastNameOrder = formData.lastName;
          const mobileNoInput = formData.mobileNo;
          const emailInput = formData.email;
          const passwordInput = formData.password;

          const registrationData = {
            name: `${firstNameOrder} ${LastNameOrder}` || "",
            mobileNo: mobileNoInput || "",
            email: emailInput || "",
            password: passwordInput || "",
          };

          const res = await addNewUser(registrationData);

          if (res.data) {
            if (res.data.status === "ok") {
              const response = await login({
                email: registrationData.email,
                password: registrationData.password,
              });

              const userData = response?.data?.data;
              if (response?.data?.data) {
                dispatch(setUser(response?.data?.data));
                dispatch(setAccessToken(response?.data?.data?.accessToken));
                dispatch(setRefreshToken(response?.data?.data?.refreshToken));
                localStorage.setItem("userData", JSON.stringify(userData));

                localStorage.setItem(
                  "authToken",
                  response?.data?.data?.accessToken
                );
                localStorage.setItem(
                  "refreshToken",
                  response?.data?.data?.refreshToken
                );
                userId = response?.data?.data?._id;

                console.log("User registered successfully:");
                toast.success("User registered successfully!");
              } else {
                console.error("User registration failed");
                toast.error("User registration failed!");
              }
            } else if (
              res.data.status === "Failed" &&
              res.data.msg === "Email aleardy Exist"
            ) {
              console.error("User is already registered");
              toast.error(res.data.msg);
            } else {
              console.error("User registration failed");
              toast.error(res.data.msg);
            }
          } else {
            console.error("User registration failed");
            toast.error(res.data.msg);
          }
        }

        setIsButtonDisabled(true);

        const firstNameOrder = formData.firstName;
        const LastNameOrder = formData.lastName;
        const mobileOrder = formData.mobileNo;
        const companyorder = formData.companyName;
        const streetOrder = formData.streetName;
        const streetNoOrder = formData.streetNo;
        const postalCorder = formData.postalCode;
        const townCityOrder = formData.townCity;
        const provinceOrder = formData.province;
        const countryorder = formData.country;

        const orderData = {
          userId: userId,
          name: `${firstNameOrder} ${LastNameOrder}` || "",
          mobileNo: mobileOrder || "",
          companyName: companyorder || "",
          streetName: streetOrder || "",
          streetNo: streetNoOrder || "",
          postalCode: postalCorder || "",
          townCity: townCityOrder || "",
          province: provinceOrder || "",
          country: countryorder || "",
          courses: [
            {
              _id: courseId,
              quantity: 1,
            
            },
          ],
        };

        const response = await axios.post(
          `${BASE_URL}/api/invitedOrder`,
          orderData
        );

        if (response.data.success) {
          toast.success("order placed sucessfully");
          setTimeout(() => {
            navigate("/dashboard");
          }, 3000);
        }
        setIsButtonDisabled(false);
      } else {
        console.log(recaptchaResponse);
        recaptcha.current.reset();
        toast.error("failed to Verify captcha");
      }
    } catch (error) {
      console.error("Error placing the orderss:", error);
      setIsButtonDisabled(false);
    }
  };

  return (
    <>
      <Header />
      <Breadcrumb pageTitle="Course Invitation" />
      <div className="container flex flex-col px-5 py-5 bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="mb-4">
            <label htmlFor="firstName" className="block  font-medium mb-1">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              placeholder="Enter your first name"
              className="w-full border border-gray-300 rounded-md p-2"
              onChange={handleInputChange("firstName")} // Add this line
              required={!user} // Make the field required only when the user is not logged in
            />
            {isButtonClicked && !fieldValidations.firstName.isValid && (
              <p className="text-red-500">
                {fieldValidations.firstName.errorMessage}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="lastName" className="block  font-medium mb-1">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              placeholder="Enter your Last name"
              className="w-full border border-gray-300 rounded-md p-2"
              onChange={handleInputChange("lastName")} // Add this line
              required={!user} // Make the field required only when the user is not logged in
            />
            {isButtonClicked && !fieldValidations.lastName.isValid && (
              <p className="text-red-500">
                {fieldValidations.lastName.errorMessage}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="mobileNo" className="block  font-medium mb-1">
              Mobile Number
            </label>
            <input
              type="number"
              name="mobileNo"
              placeholder="Enter your Mobile Number"
              className="w-full border border-gray-300 rounded-md p-2"
              onChange={handleInputChange("mobileNo")} // Add this line
              required={!user} // Make the field required only when the user is not logged in
            />
            {isButtonClicked && !fieldValidations.mobileNo.isValid && (
              <p className="text-red-500">
                {fieldValidations.mobileNo.errorMessage}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="companyName" className="block  font-medium mb-1">
              Company Name (optional)
            </label>
            <input
              type="text"
              name="companyName"
              placeholder="Enter company name"
              onChange={handleInputChange("companyName")} // Add this line
              className="w-full border border-gray-300 rounded-md p-2"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="streetName" className="block  font-medium mb-1">
              Country
            </label>
            <CountryDropdown
              //   id="country"
              name="country"
              value={fieldValidations.country.value}
              onChange={(val) => handleCountryChangeoption(val)}
              className="w-full border border-gray-300 rounded-md p-2"
            />
            {isButtonClicked && !fieldValidations.country.isValid && (
              <p className="text-red-500">
                {fieldValidations.country.errorMessage}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="streetName" className="block  font-medium mb-1">
              Province
            </label>
            <RegionDropdown
              //   id="province"
              name="province"
              country={fieldValidations.country.value}
              value={fieldValidations.province.value}
              onChange={(val) => handleRegionChangeption(val)}
              className="w-full border border-gray-300 rounded-md p-2"
              disableWhenEmpty
            />
            {isButtonClicked && !fieldValidations.province.isValid && (
              <p className="text-red-500">
                {fieldValidations.province.errorMessage}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="townCity" className="block  font-medium mb-1">
              Town / City
            </label>
            <input
              type="text"
              //   id="townCity"
              name="townCity"
              placeholder="Enter town or city"
              onChange={handleInputChange("townCity")} // Add this line
              className="w-full border border-gray-300 rounded-md p-2"
              required
            />
            {isButtonClicked && !fieldValidations.townCity.isValid && (
              <p className="text-red-500">
                {fieldValidations.townCity.errorMessage}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="streetName" className="block  font-medium mb-1">
              Street Name & Number
            </label>
            <input
              type="text"
              //   id="streetName"
              name="streetName"
              placeholder="Enter street name"
              onChange={handleInputChange("streetName")} // Add this line
              className="w-full border border-gray-300 rounded-md p-2"
              required
            />
            {isButtonClicked && !fieldValidations.streetName.isValid && (
              <p className="text-red-500">
                {fieldValidations.streetName.errorMessage}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label htmlFor="postalCode" className="block  font-medium mb-1">
              Postal Code
            </label>
            <input
              type="text"
              //   id="postalCode"
              name="postalCode"
              placeholder="Enter postal code"
              onChange={handleInputChange("postalCode")} // Add this line
              className="w-full border border-gray-300 rounded-md p-2"
              required
            />
            {isButtonClicked && !fieldValidations.postalCode.isValid && (
              <p className="text-red-500">
                {fieldValidations.postalCode.errorMessage}
              </p>
            )}
          </div>
        </div>
        {!user && (
          <>
            <h2 className="text-2xl font-semibold text-main mb-4">
              Account Creation{" "}
            </h2>
            <div className="flex">
              <div className="mb-4 w-100 px-2">
                <label htmlFor="email" className="block  font-medium mb-1">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  value={email}
                  placeholder="Enter your Email"
                  className="w-full border border-gray-300 rounded-md p-2"
                  //   onChange={handleInputChange("email")}
                  required={!user}
                  disabled
                />
                <p className="">
                  This email will be used to create your new account.
                </p>
                {/* {isButtonClicked && !fieldValidations.email.isValid && (
                  <p className="text-red-500">
                    {fieldValidations.email.errorMessage}
                  </p>
                )} */}
              </div>

              <div className="mb-4 w-100 px-2">
                <label htmlFor="password" className="block  font-medium mb-1">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder="Enter Your Password"
                  className="w-full border border-gray-300 rounded-md p-2"
                  onChange={handleInputChange("password")}
                  required={!user}
                />
                <p>Create a password that will keep your new account safe.</p>
                {isButtonClicked && !fieldValidations.password.isValid && (
                  <p className="text-red-500">
                    {fieldValidations.password.errorMessage}
                  </p>
                )}
              </div>
            </div>
          </>
        )}
        <div className="flex mt-2">
          <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
        </div>

        <button onClick={handlePlaceOrder} className="header-btn mt-4">
          Create Account & Accept Invitation
        </button>
      </div>
    </>
  );
};

export default Invited;
