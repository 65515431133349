import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Preloader from "../components/Preloader";
import { useDispatch, useSelector } from "react-redux";
import { useGetCourseQuery } from "../Redux/Services/Courseservice";

import {
  setAccessToken,
  setIsTableLoading,
  setUser,
} from "../Redux/Slice/authSlice";
import {
  setCourse,
  setIsTableLoading as coursesetIsTableLoading,
} from "../Redux/Slice/CourseSlice";

//Custom Components
import MainLayout from "../components/Layout/Mainlayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import Home from "../pages/home";
import HomeTwo from "../pages/home-2";
import HomeThree from "../pages/home-3";
import About from "../pages/about";
import Course from "../pages/course";
import CourseList from "../pages/course/course-list";
import CourseDetails from "../pages/course/course-details";
import CourseSidebar from "../pages/course/course-sidebar";
import Instructor from "../pages/instructor";
import InstructorDetails from "../pages/instructor/instructor-details";
import Event from "../pages/event";
import EventSidebar from "../pages/event/event-sidebar";
import EventDetails from "../pages/event/event-details";
import Blog from "../pages/blog";
import BlogDetails from "../pages/blog/blog-details";
import Login from "../pages/authentication/login";
import Signup from "../pages/authentication/signup";
import Contact from "../pages/contact";
import Error from "../pages/404";
import LoadTop from "../components/ScrollTop/LoadTop";
import Receipt from "../pages/Dashboard/Receipt";
import AccountInfo from "../pages/Dashboard/AccountInfo";
import CourseModule from "../pages/Dashboard/CourseModule";
import PlayModule from "../pages/Dashboard/PlayModule";
import QuizModule from "../pages/Dashboard/QuizModule";
import FinalQuizModule from "../pages/Dashboard/FinalQuizModule";
import Invoice from "../components/Invoice/Invoice";
import Success from "../pages/Sucess";
import Cancel from "../pages/Cancel";
import Checkout from "../pages/Checkout/Checkout";
import SingleCourse from "../pages/Signle Course/Course";
import ForgotPassword from "../pages/authentication/ForgotPassword";
import CorporateCheckout from "../pages/Checkout/CoporateCheckout";
import Invite from "../pages/Invite/Invite";
import Invited from "../pages/Invite/Invited";
import Cart from "../components/Cart/Cart";
import Faq from "../components/Faq/index";
import FaqPart from "../pages/home-2/FaqSection";
import { FaQ } from "react-icons/fa6";
import FAQ from "../components/Faq/Faqpage";
import PrivacyPolicy from "../pages/privacy_policy/index";
import Terms_Condition from "../pages/terms&condition/index";
import { isValidAdmin } from "../helpers/AuthHelper";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate data loading delayS
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  const dispatch = useDispatch();
  const { isTableLoading } = useSelector((state) => state.auth);
  const { data, isFetching, isLoading: isCourseLoading } = useGetCourseQuery();

  useEffect(() => {
    const checkForValidUser = async () => {
      const userDataString = localStorage.getItem("userData");
      const userData = JSON.parse(userDataString);

      if (userData && userData.accessToken) {
        const result = await isValidAdmin(userData.accessToken);
        if (!result) {
          localStorage.clear();
        } else {
          dispatch(setUser(userData));
          dispatch(setAccessToken(userData.accessToken));
        }
      }
      dispatch(setIsTableLoading(true));
    };
    checkForValidUser();
  }, [dispatch]);

  useEffect(() => {
    if (!isCourseLoading && !isFetching) {
      dispatch(setCourse(data?.data));
    } else {
      dispatch(coursesetIsTableLoading(true));
    }
  }, [dispatch, data, isFetching, isCourseLoading]);

  if (!isTableLoading) {
    return null;
  }

  return (
    <div className="App">
      {isLoading ? <Preloader /> : ""}
      <>
        <LoadTop />
        <Routes>
          <Route
            element={
              <MainLayout>
                <Dashboard />
              </MainLayout>
            }
            path="/dashboard"
          />
          <Route
            element={
              <MainLayout>
                <Receipt />
              </MainLayout>
            }
            path="/dashboard/receipt"
          />
          <Route
            element={
              <MainLayout>
                <AccountInfo />
              </MainLayout>
            }
            path="/dashboard/accountinfo"
          />
          <Route
            element={
              <MainLayout>
                <CourseModule />
              </MainLayout>
            }
            path="/coursemodule/:slug"
          />
          <Route
            element={
              <MainLayout>
                <Invoice />
              </MainLayout>
            }
            path="/dashboard/invoice/:id"
          />
          <Route
            element={
              <MainLayout>
                <Invite />
              </MainLayout>
            }
            path="/invite"
          />
          <Route
            element={<Invited />}
            path="/invited/:email/:courseId/:orderId"
          />
          <Route element={<Cart />} path="/cart" />
          <Route element={<PlayModule />} path="/courseplay" />
          <Route element={<QuizModule />} path="/quizmodule" />
          <Route element={<FinalQuizModule />} path="/finalQuiz" />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
          <Route element={<Checkout />} path="/checkout" />
          <Route element={<ForgotPassword />} path="/forgotpassword" />

          <Route path="/" exact element={<Home />} />
          <Route path="/home-2" element={<HomeTwo />} />
          <Route path="/home-3" element={<HomeThree />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" exact element={<Course />} />
          <Route path="/course-list" exact element={<CourseList />} />
          {/* <Route path="/course/:id" element={<SingleCourse />} /> */}
          <Route path="/course/:slug" element={<CourseDetails />} />
          <Route path="/course-sidebar" element={<CourseSidebar />} />
          <Route path="/event" exact element={<Event />} />
          <Route path="/event/:id" element={<EventDetails />} />
          <Route path="/event-sidebar" element={<EventSidebar />} />
          <Route path="/blog" exact element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetails />} />
          <Route path="/instructor" exact element={<Instructor />} />
          <Route path="/instructor/:id" exact element={<InstructorDetails />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/terms&conditions" element={<Terms_Condition />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />

          <Route path="*" element={<Error />} />
        </Routes>
      </>
    </div>
  );
};

export default App;
