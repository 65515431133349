import React from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../Utils/constant";
import courseImg1 from "../../assets/images/course/1.png";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../Redux/Slice/CartSlice";
import { useNavigate } from "react-router-dom";

const SingleCourse = (props) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { course: courses } = useSelector((state) => state.course);
  const { selectedCourse, isTableLoading } = useSelector(
    (state) => state.course
  );
  const handleAddToCart = () => {
    dispatch(setCart({ courses: selectedCourse, quantity: 1 }));
    navigate("/checkout");
  };
  const {
    itemClass,
    courseID,
    courseImg,
    courseTitle,
    courseSubTitle,
    courseOverView,
    coursePrice,
    courseSlug,
  } = props;

  return (
    <div className={itemClass ? itemClass : "course__item mb-30"}>
      <div className="course__thumb">
        <Link to={`/course/${courseSlug}`}>
          <img
            src={`${BASE_URL}${courseImg}`}
            alt={courseTitle}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = courseImg1;
            }}
          />
        </Link>
      </div>
      <div className="course__inner">
        <ul className="h-12">
          <li>{courseSubTitle ? courseSubTitle : "Unkown"}</li>
        </ul>
        <h3 className="react-course-title">
          <Link to={`/course/${courseSlug}`}>
            {courseTitle
              ? courseTitle
              : "Dave conservatoire is the Entirely free online."}
          </Link>
        </h3>
        <div className="course__card-icon d-flex align-items-center">
          <div className="course__card-icon--1">
          {/* <button
                  onClick={() => handleAddToCart()}
                  className="start-btn w-full"
                  style={{ width: "-webkit-fill-available", color:"white", background:"#f99631", padding: "10px 15px", borderRadius: "10px"}}
                >
                  Buy Now
            </button> */}
          </div>
          <div className="react__user">
              {coursePrice === 0 ? "Free" : coursePrice.toLocaleString('en-CA', { style: 'currency', currency: 'CAD' })}
          </div>

        </div>
      </div>
    </div>
  );
};

export default SingleCourse;
