export const isValidEmail = (email) => {
  var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return pattern.test(email);
};

export const isValidMobileNumber = (number) => {
  var pattern = /^[0-9]{10}$/;

  return pattern.test(number);
};
