import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/Breadcrumb";
import LoginMain from "./LoginMain";
import ScrollToTop from "../../components/ScrollTop";
import Logo from "../../assets/images/logos/logo2.png";

const Login = () => {
  return (
    <>
      <Header
        parentMenu="page"
        menuCategoryEnable="enable"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
      />
      <div className="react-wrapper">
        <div className="react-wrapper-inner">
          <Breadcrumb pageTitle="Login" />
          <LoginMain />
          <ScrollToTop />
        </div>
      </div>
          <Footer />
    </>
  );
};

export default Login;
