import React, { useEffect } from "react";
import Quiz from "../../components/Single Course/Quiz/Quiz";
import { useGetQuestionByModuleIdQuery } from "../../Redux/Services/questionService";
import {
  setIsTableLoading,
  setQuizData,
} from "../../Redux/Slice/questionSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const quizQuestions = [
  {
    question: "What is the capital of France?",
    options: ["Berlin", "Madrid", "Paris", "Rome"],
    correctOption: "Paris",
  },
  {
    question: "Which planet is known as the Red Planet?",
    options: ["Earth", "Mars", "Venus", "Jupiter"],
    correctOption: "Mars",
  },
];

const QuizModule = ({ id }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const quizModuleId = queryParams.get("moduleId");
  const { quizData } = useSelector((state) => state.question);
  const { data, isFetching, isLoading } =
    useGetQuestionByModuleIdQuery(quizModuleId);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setQuizData(data?.questions));
    } else {
      dispatch(setIsTableLoading(true));
    }
  }, [dispatch, data, isFetching, isLoading]);

  return <>{isLoading ? <p>Loading ...</p> : <Quiz questions={quizData} />}</>;
};

export default QuizModule;
