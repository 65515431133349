import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useGetCourseByIdQuery,
  useGetFinalQuizResultQuery,
} from "../../Redux/Services/Courseservice";
import {
  setIsTableLoading,
  setSelectedCourse,
} from "../../Redux/Slice/CourseSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../Utils/constant";
import { ImLoop } from "react-icons/im";

const CourseContentRow = ({ courseId, setQuizResult, setFinalQuizResult }) => {
  const params = useParams();
  const courseSlug = params.slug;
  const orderId = localStorage.getItem("currentOrderId");
  const selectedCourseId = localStorage.getItem("selectedCourseId");

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const userId = user._id;
  const { data, isFetching, isLoading } = useGetCourseByIdQuery(courseSlug);
  const [userProgress, setUserProgress] = useState(null);
  const [statusss, setStatussss] = useState([]);
  const { selectedCourse } = useSelector((state) => state.course);
  const [statuses, setStatuses] = useState([]);
  const playLink = "/courseplay";
  const quizLink = "/quizmodule";
  const modulesData = selectedCourse?.modules;
  const { data: finalQuizResult, isLoading: isFinalQuizLoading } =
    useGetFinalQuizResultQuery({
      userId: userId,
      courseId: courseId ? courseId : selectedCourseId,
      orderId: orderId,
    });
  useEffect(() => {
    let status = finalQuizResult?.result?.completed ? "Completed" : null;
    setFinalQuizResult(status);
  }, [finalQuizResult?.result?.completed, setFinalQuizResult]);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setSelectedCourse(data?.data));
    } else {
      dispatch(setIsTableLoading(true));
    }
  }, [dispatch, data, isFetching, isLoading]);

  useEffect(() => {
    const fetchUserProgress = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/user-progress/${userId}/${
            courseId ? courseId : selectedCourseId
          }/${orderId}`
        );
        setUserProgress(response.data);
      } catch (error) {
        console.error("Error fetching user progress:", error);
      }
    };

    fetchUserProgress();
  }, [userId, courseId]);

  useEffect(() => {
    const fetchQuizResults = async () => {
      try {
        let filterModule = modulesData?.filter((module) =>
          module.name.toLowerCase().startsWith("module")
        );
        let quizResults = await Promise.all(
          filterModule?.map(async (module) => {
            const moduleId = module?._id;

            try {
              // Try to fetch the quiz result for the current module and user
              const response = await axios.get(
                `${BASE_URL}/api/results/${userId}/${moduleId}/${orderId}`
              );
              const quizResult = response.data;
              if (quizResult) {
                if (quizResult.completed) {
                  return { status: "Completed", moduleId };
                } else {
                  return { status: "Not Started Yet", moduleId };
                }
              }
            } catch (error) {
              // Handle the case when there is an error fetching the quiz result
              console.error(
                `Error fetching quiz result for module ${moduleId}:`,
                error
              );
              return "Not Started yet";
            }
          })
        );
        quizResults = quizResults.filter((element) => element !== undefined);
        // console.log(quizResults);

        // Set the updated module statuses in state or use them as needed
        setStatussss(quizResults);
        setQuizResult(quizResults);
      } catch (error) {
        console.error("Error fetching quiz results:", error);
      }
    };

    fetchQuizResults();
  }, [userId, modulesData]);

  useEffect(() => {
    const updateModuleStatuses = async () => {
      const moduleStatuses = await Promise.all(
        selectedCourse?.modules?.map(async (module) => {
          const userModule = userProgress?.moduleProgress?.find(
            (userModule) => userModule?.moduleId === module?._id
          );

          if (
            userModule &&
            module?.parts.every((part) =>
              userModule?.partsCompleted.some(
                (completedPart) => completedPart.partNumber === part.partNumber
              )
            )
          ) {
            return { status: "Completed", moduleId: module._id };
          } else {
            return { status: "Not Started yet", moduleId: module._id };
          }
        }) || []
      );

      setStatuses(moduleStatuses);
    };
    updateModuleStatuses();
  }, [userProgress, selectedCourse]);

  const quizStatusRender = (moduleId) => {
    const index = statusss.findIndex((item) => item.moduleId === moduleId);
    return statusss[index]?.status;
  };
  const quizButtonRenderer = (moduleId, moduleIndex) => {
    let quizIndex = statusss.findIndex((item) => item.moduleId === moduleId);

    if (
      statuses[moduleIndex]?.moduleId === statusss[quizIndex]?.moduleId &&
      statuses[moduleIndex]?.status === "Completed"
    ) {
      return (
        <Link
          to={{
            pathname: quizLink,
            search: `?courseSlug=${courseSlug}&moduleId=${moduleId}`,
          }}
          className="view-text"
        >
          <span>Start Quiz</span>
        </Link>
      );
    } else {
      return (
        <button disabled className="view-text cursor-not-allowed">
          <span>Start Quiz</span>
        </button>
      );
    }
  };
  const moduleButtonRenderer = (moduleId, moduleIndex) => {
    if (moduleIndex === 0) {
      return (
        <Link
          to={{
            pathname: playLink,
            search: `?courseSlug=${courseSlug}&moduleId=${moduleId}`,
          }}
          className={`view-text`}
        >
          <span>Play Module</span>
        </Link>
      );
    } else if (
      moduleIndex > 0 &&
      statuses[moduleIndex - 1]?.status === "Completed"
    ) {
      return (
        <Link
          to={{
            pathname: playLink,
            search: `?courseSlug=${courseSlug}&moduleId=${moduleId}`,
          }}
          className={`play-btn view-text`}
        >
          <span>Play Module</span>
        </Link>
      );
    } else {
      return (
        <button disabled className={`cursor-not-allowed play-btn view-text`}>
          <span>Play Module</span>
        </button>
      );
    }
  };

  return (
    <>
      {!modulesData && (
        <tr>
          <td colSpan="3">Loading...</td>
        </tr>
      )}

      {modulesData?.map((module, index) => (
        <React.Fragment key={module._id}>
          <tr role="row" className="w-full bg-slate-200 border-t border-b ">
            <td className="p-2 py-4 border-r">
              <div className="es-launch-course__course-content__module">
                <span className="es-launch-course__course-content__module__name mr-2 ">
                  {module.name}
                </span>
                {/* <span className="es-launch-course__course-content__module__duration">
                  {duration}
                </span> */}
              </div>
            </td>
            <td className="py-4 pl-2 border-r">
              <div
                className={`es-badge--${
                  statuses[index]?.status === "Completed"
                    ? "success"
                    : "neutral"
                } text-nowrap es-badge--sm flex items-center gap-3`}
              >
                <span className="align-middle">{statuses[index]?.status}</span>
              </div>
            </td>
            <td className="py-4">
              <div className="d-flex justify-center">
                {moduleButtonRenderer(module._id, index)}
              </div>
            </td>
          </tr>
          {module.name.toLowerCase().startsWith("module") && quizLink && (
            <tr role="row" className="w-full">
              <td className="p-2 py-4 pl-10 border-r">
                <div className="es-launch-course__course-content__quiz">
                  ↳ QUIZ
                </div>
              </td>
              <td className="py-4 pl-2 border-r">
                <div
                  className={`es-badge--${
                    statusss[index]?.status === "Completed"
                      ? "success"
                      : "neutral"
                  } text-nowrap es-badge--sm flex items-center gap-3`}
                >
                  <span className="align-middle">
                    {quizStatusRender(module._id)}
                  </span>
                </div>
              </td>
              <td className="py-4">
                <div className="d-flex justify-center">
                  {quizLink && <>{quizButtonRenderer(module._id, index)}</>}
                </div>
              </td>
            </tr>
          )}
        </React.Fragment>
      ))}
      {/* Final Quiz  */}
      <tr role="row" className="w-full bg-slate-200 ">
        <td className="p-2 py-4 border-r ">Final Quiz</td>
        <td className="py-4 pl-2">
          {isFinalQuizLoading ? (
            "loading..."
          ) : (
            <>
              {finalQuizResult?.result?.completed ? "Completed" : "InComplete "}
            </>
          )}
        </td>
        <td className="py-4  border-l d-flex justify-center">
          {(statusss.length === 0 ||
            statusss[statusss.length - 1]?.status === "Completed") &&
          statuses[statuses.length - 1]?.status === "Completed" ? (
            <Link
              to={{
                pathname: "/finalQuiz",
                search: `?courseId=${courseId}&courseSlug=${courseSlug}`,
              }}
              className="view-text"
            >
              <span className="d-flex justify-center">Go to Quiz</span>
            </Link>
          ) : (
            <button disabled className="view-text cursor-not-allowed">
              <span className="d-flex justify-center">Go to Quiz</span>
            </button>
          )}
        </td>
      </tr>
    </>
  );
};

export default CourseContentRow;
