import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle";

import SingleCourse from "../../components/Course/SingleCourse";

import courses from "../../data/Courses.json";
import { useGetCourseQuery } from "../../Redux/Services/Courseservice";
import { useDispatch, useSelector } from "react-redux";
import { setCourse } from "../../Redux/Slice/CourseSlice";

const Course = () => {
  const dispatch = useDispatch();
  const { data, isFetching, isLoading } = useGetCourseQuery();
  const { course } = useSelector((state) => state.course);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setCourse(data?.data));
    }
  }, [dispatch, data, isFetching, isLoading]);

  return (
    <div className="popular__course__area pt---100 pb---100">
      <div className="container">
        <SectionTitle Title="Popular Courses" />
        <div className="row">
          {data?.data
            ?.map((data, index) => {
              return (
                <div
                  key={index}
                  className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 wow animate__fadeInUp"
                  data-wow-duration="0.3s"
                >
                  {
                    <SingleCourse
                      courseID={data._id}
                      courseImg={data.coverImage}
                      courseTitle={data.title}
                      courseSubTitle={data.subTitle}
                      courseOverView={data.overview}
                      coursePrice={data.price}
                      courseSlug={data.slug}
                    />
                  }
                </div>
              );
            })
            .slice(0, 3)}
        </div>
        <div className="text-center">
          <Link to="/courses" className="view-courses">
            {" "}
            View All Courses{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Course;
