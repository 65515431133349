import apiSlice from "./apiSlice";

export const courseApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCourse: builder.query({
      providesTags: ["course"],
      query: (body) => ({
        url: "api/courses",
        method: "GET",
        body,
      }),
    }),

    getCourseDetailByModuleId: builder.query({
      providesTags: ["course"],
      query: ({ courseId, moduleId }) => ({
        url: `api/course/${courseId}/module/${moduleId}`,
        method: "GET",
      }),
    }),

    addCourse: builder.mutation({
      invalidatesTags: ["course"],
      query: (body) => ({
        url: "api/course",
        method: "POST",
        body,
      }),
    }),
    getCourseById: builder.query({
      providesTags: ["course"],
      query: (slug) => ({
        url: `api/course/${slug}`,
        method: "GET",
      }),
    }),
    getCompletionCertificate: builder.query({
      providesTags: ["course"],
      query: () => ({
        url: `api/course/completionCertificate`,
        method: "GET",
      }),
    }),

    getFinalQuizOfCourse: builder.query({
      providesTags: ["course"],
      query: (courseId) => ({
        url: `api/finalQuiz/${courseId}`,
        method: "GET",
      }),
    }),
    getFinalQuizResult: builder.query({
      query: ({ userId, courseId, orderId }) => ({
        url: `api/finalQuiz/results/${userId}/${courseId}/${orderId}`,
        method: "GET",
      }),
    }),

    inviteUserToCourse: builder.mutation({
      query: (body) => ({
        url: `api/invite`,
        method: "PUT",
        body,
      }),
    }),

    getInvitedList: builder.query({
      query: (userId) => ({
        url: `api/invited-list/${userId}`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useGetCourseQuery,
  useGetCourseByIdQuery,
  useGetCourseDetailByModuleIdQuery,
  useGetCompletionCertificateQuery,
  useGetFinalQuizOfCourseQuery,
  useGetFinalQuizResultQuery,
  useInviteUserToCourseMutation,
  useGetInvitedListQuery,
} = courseApi;
