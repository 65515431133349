import React, { useState } from "react";

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor'; 

const Counter = () => {

    const [state, setState] = useState(true);

    const counters = [
        {
            countNum: 67,
            countTitle: 'Canadian organizations',
        },
        {
            countNum: 1056,
            countTitle: 'Enrolled Candidatess',
        }

    ];

    return (
        <div className="count__area pb---110">
            <div className="container count__width">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 offset-lg-1">
                            {counters &&
                                <div className="row flex">
                                    {counters.map( (counter, num , countTitle) => (
                                        <div key={num} className="col-xxl-5 col-xl-5 col-lg-5 col-md-3 col-sm-6 wow animate__fadeInUp" data-wow-duration="0.3s">
                                            <div className="count__content">
                                                <p className="count__content--paragraph">{counter.countTitle}</p>
                                                <h3 className="count__content--title-1 counter">
                                                    <CountUp start={state ? 0 : counter.countNum} end={counter.countNum} duration={10} onEnd= {()=> setState(false)} />
                                                    {({ countUpRef, start }) => (
                                                        <VisibilitySensor onChange={start} delayedCall>
                                                            <span ref={countUpRef} />
                                                        </VisibilitySensor>
                                                    )}+
                                                </h3>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
            </div>
        </div>
    );
}
export default Counter;