import apiSlice from "./apiSlice";

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //***** GET *****/
    getUsers: builder.query({
      providesTags: ["user"],
      query: (body) => ({
        url: "api/users",
        method: "GET",
        body,
      }),
    }),
    //***** LOGIN *****/
    login: builder.mutation({
      invalidatesTags: ["user"],
      query: (body) => ({
        url: "api/login",
        method: "POST",
        body,
      }),
    }),
    //***** LOG OUT *****/
    logout: builder.mutation({
      invalidatesTags: ["user"],
      query: () => ({
        url: "api/logout",
        method: "GET",
      }),
    }),

    //***** LOG OUT FROM ALL DEVICES *****/
    logoutFromAll: builder.mutation({
      invalidatesTags: ["user"],
      query: (body) => ({
        url: "/user/logout",
        method: "POST",
        body,
      }),
    }),

    //***** ADD New User*****/
    addNewUser: builder.mutation({
      invalidatesTags: ["user"],
      query: (body) => ({
        url: "api/register",
        method: "POST",
        body,
      }),
    }),
    updateUser: builder.mutation({
      invalidatesTags: ["user"],
      query: (body) => ({
        url: `api/user/${body?._id}`,
        method: "PUT",
        body,
      }),
    }),
    verifyRecaptcha: builder.mutation({
      invalidatesTags: ["recaptcha"],
      query: (body) => ({
        url: `api/recaptcha/verify`,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  useGetUsersQuery,
  useLoginMutation,
  useLogoutMutation,
  useLogoutFromAllMutation,
  useAddNewUserMutation,
  useUpdateUserMutation,
  useVerifyRecaptchaMutation,
} = userApi;
