import React from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

const Faq = () => {
  return (
    <div className="accordion__wrapper1 container pt-20 pb-16">
      <Accordion className="accordion" preExpanded={"a"}>
        <AccordionItem className="accordion-item" uuid="a">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            What courses do you offer?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            We offer a range of workplace safety courses including WHMIS, PPE, Ladder Safety, and many others. You can view the full list of our courses <Link to="https://workplacesafetytraining.net/courses">here</Link>.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="b">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            How do I register for a course?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            To register for a course, visit our courses page, select the course you are interested in, and follow the registration instructions. If you need assistance, you can also contact our support team.
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="c">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            Who are these courses designed for?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            Our courses are designed for employees, managers, and safety professionals who want to ensure safety in the workplace. They are suitable for anyone looking to improve their knowledge and compliance with safety standards. 
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="d">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            Can I complete the courses online?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            Yes, all our courses are available online. You can complete them at your own pace and convenience from anywhere. 
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="e">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            What certification will I receive upon completing a course?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            Upon successful completion of each course, you will receive a certificate that recognizes your understanding and commitment to workplace safety. 
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="f">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            How long do I have access to the course materials?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            After enrolling, you will have access to the course materials for a period that varies by course. Typically, this is between 6 to 12 months. 
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="g">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            What if I have technical issues accessing a course?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            If you experience any technical issues, please contact our support team immediately. We are here to help ensure your learning experience is smooth and efficient. 
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="h">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            Are there any prerequisites for enrolling in the courses?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            Most courses do not require prerequisites, but some specialized courses might. Please check the course description for specific details. 
            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="i">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            Is there a group discount for businesses enrolling multiple employees?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            Yes, we offer group discounts for businesses looking to train multiple employees. Please contact our sales team for more information on pricing and packages.            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="j">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            How can I provide feedback on a course?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            Your feedback is important to us. After course completion, you will be prompted to fill out a feedback form. Alternatively, you can contact our customer service at any time to provide your thoughts and suggestions.            </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="k">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            What payment methods are accepted for course registration?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            We accept a variety of payment methods including credit cards, PayPal, and bank transfers. All payment information is securely processed.             </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="l">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            Can I cancel my course registration?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            Yes, you can cancel your course registration. If you cancel within 7 days of enrolment, you will receive a full refund. Please refer to our cancellation policy for more details.             </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="m">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            Do you update the courses regularly?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            Yes, our courses are regularly updated to reflect the latest safety standards and regulations. We ensure all course materials are current and relevant.             </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="n">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            What support is available to students during the course?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            We offer support via email, live chat, and phone. Our team of experts is available to assist you with any course-related queries during your learning journey.             </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="o">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            How are the courses structured?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            Our courses typically include a mix of video lectures, interactive quizzes, and real-world case studies to provide a thorough understanding of each topic.             </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="p">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            Can I track my progress in a course?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            Yes, our learning platform allows you to track your progress throughout the course. You can see which modules you've completed and what remains.             </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="q">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            Is there a final exam for each course?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            Most courses have a final exam to assess your understanding of the material. You need to pass this exam to receive your certification.             </p>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="r">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            What if I fail the final exam?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            If you do not pass the final exam on your first attempt, you can retake the exam. Details about retakes and any associated costs can be found in the course information. </p>          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="s">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            Can I access the course materials after completing the course?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            Yes, you can access the course materials for a review for a specified period after course completion. The duration of access may vary by course.</p>          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className="accordion-item" uuid="t">
          <AccordionItemHeading className="accordion-button">
            <AccordionItemButton className="card-link">
            Are your courses recognized by industry regulators?             
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-body">
            <p>
            Yes, our courses are recognized and accredited by leading industry safety regulators. This ensures that our training meets high standards and is compliant with industry requirements. </p>          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Faq;
