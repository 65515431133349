import React from "react";
import { Link } from "react-router-dom";
import courseImg1 from "../../assets/images/course/1.png";
import coursetypeIcon from "../../assets/images/course/beg.png";
import { BASE_URL } from "../../Utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../Redux/Slice/CartSlice";
import { useNavigate } from "react-router-dom";


const SingleCourseThree = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { course: courses } = useSelector((state) => state.course);
  const { selectedCourse, isTableLoading } = useSelector(
    (state) => state.course
  );

  const handleAddToCart = () => {
    dispatch(setCart({ courses: selectedCourse, quantity: 1 }));
    navigate("/checkout");
  };
  const {
    itemClass,
    courseID,
    courseImg,
    courseTitle,
    courseType,
    coursePrice,
    courseRating,
    courseOverview,
    courseSlug,
  } = props;
  const renderCourseImage = () => {
    if (courseImg === null || courseImg === undefined || courseImg === "") {
      return courseImg1;
    }
    return `${BASE_URL}${courseImg}`;
  };
  return (
    <div className={itemClass ? itemClass : "single-studies grid-item"}>
      <div className="inner-course">
        <Link to={`/course/${courseSlug}`}>
          <div className="case-img">
            {/* <Link to="#" className="cate-w">
              <img src={coursetypeIcon} alt={courseType} />
              {courseType ? courseType : "Beginner"}
            </Link> */}
            <img
              src={renderCourseImage()}
              alt={courseTitle}
              className="courseImg"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = courseImg1;
              }}
            />
          </div>
        </Link>
        <div className="case-content">
          <h4 className="case-title">
            {" "}
            <Link to={`/course/${courseSlug}`}>
              {courseTitle
                ? courseTitle
                : "The Most Complete Design Thinking Course On The Market."}
            </Link>
          </h4>
          <ul className="meta-course">
            <li>
              {courseOverview 
                ? (courseOverview.length > 155 
                    ? courseOverview.slice(0, 155) + '...' 
                    : courseOverview) 
                : "Unknown"}
            </li>
          </ul>

            <ul className="react-ratings">
            <li className="react-book">
              <em>{courseRating}</em>
              {Array.from({ length: courseRating }).map((_, index) => (
                <span key={index} className="icon_star"></span>
              ))}
            </li>
            <li className="price">
            {coursePrice === 0 ? "Free" : coursePrice.toLocaleString('en-CA', { style: 'currency', currency: 'CAD' })}
            </li>
            
          </ul>
            {/* <button
                  onClick={() => handleAddToCart()}
                  className="start-btn w-full"
                  style={{ width: "-webkit-fill-available", color:"white", background:"#f99631", marginTop: "20px", padding: "10px 15px", borderRadius: "10px"}}
                >
                  Buy Now
            </button> */}
        </div>
      </div>
    </div>
  );
};

export default SingleCourseThree;
