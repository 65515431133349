import React, { useEffect, useRef, useState } from "react";
import VideoPlayer from "../../components/Single Course/VideoPlayer/VideoPlayer";
import { useLocation, useNavigate } from "react-router-dom";
import { setSelectedCourse } from "../../Redux/Slice/CourseSlice";
import { setIsTableLoading } from "../../Redux/Slice/CartSlice";
import { useDispatch, useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import {
  useGetCourseByIdQuery,
  useGetCourseDetailByModuleIdQuery,
} from "../../Redux/Services/Courseservice";
import GlossaryContent from "../../components/GlossaryContent/GlossaryContent";
import axios from "axios";
import { BASE_URL } from "../../Utils/constant";

const PlayModule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const courseSlug = params.get("courseSlug");
  const moduleId = params.get("moduleId");
  const [courseId, setCourseId] = useState(localStorage.getItem('selectedCourseId'));
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isMobileDevice = useMediaQuery({ maxWidth: 768 });
  const { user } = useSelector((state) => state.auth);
  const userId = user._id;
  const { data, isFetching, isLoading } = useGetCourseDetailByModuleIdQuery({
    courseId: courseId,
    moduleId: moduleId,
  });
  const orderId = localStorage.getItem("currentOrderId");

  const { data: courseData } = useGetCourseByIdQuery(courseSlug);
  const currentModuleId = moduleId;
  const currentIndex = courseData?.data?.modules?.findIndex(
    (module) => module._id === currentModuleId
  );
  const hasNextModule = currentIndex < courseData?.data?.modules?.length - 1;

  const navigateToNextModule = () => {
    if (hasNextModule) {
      const nextModuleId = courseData?.data?.modules[currentIndex + 1]?._id;
      navigate(`/courseplay?courseSlug=${courseSlug}&moduleId=${nextModuleId}`);
    }
  };

  const navigateToPrevModule = () => {
    if (currentIndex > 0) {
      const prevModuleId = courseData?.data?.modules[currentIndex - 1]?._id;
      navigate(`/courseplay?courseSlug=${courseSlug}&moduleId=${prevModuleId}`);
    }
  };

  const videoRef = useRef(null);
  const [duration, setDuration] = useState(0);

  const handleLoadedMetadata = () => {
    const videoDuration = videoRef?.current?.duration;
    console.log(Math.floor(videoDuration));
    setDuration(videoDuration);
  };

  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setSelectedCourse(data));
      setCourseId(courseData?.data?._id);
      const firstTopic = topicsWithUrls?.[0]?.topic;
      if (firstTopic) {
        handleTopicClick(topicsWithUrls?.[0]?.index);
      }
    } else {
      dispatch(setIsTableLoading(true));
    }
  }, [dispatch, data, isFetching, isLoading, courseData]);

  const moduleDetails = data?.moduleDetails;
  const topicsWithUrls = moduleDetails?.parts?.map((part, index) => ({
    topic: part?.partNumber,
    videoUrl: part?.video,
    index: index,
  }));

  const [selectedTopic, setSelectedTopic] = useState(
    topicsWithUrls?.[0] || null
  );
  const [activeTab, setActiveTab] = useState("menu");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [currentPartNumber, setCurrentPartNumber] = useState(
    selectedTopic?.partNumber || null
  );

  const handleTopicClick = (index) => {
    const selectedTopicObject = topicsWithUrls?.find(
      (item) => item?.index === index
    );
    setSelectedTopic(selectedTopicObject);
    setCurrentPartNumber(selectedTopicObject?.partNumber);
    setIsMobileMenuOpen(false);
  };

  const handlePrevious = () => {
    const currentIndex = selectedTopic?.index || 0;
    const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : 0;
    handleTopicClick(topicsWithUrls?.[prevIndex]?.index);
  };

  const handleNext = () => {
    const currentIndex = selectedTopic?.index || 0;
    const nextIndex =
      currentIndex + 1 < topicsWithUrls?.length
        ? currentIndex + 1
        : topicsWithUrls?.length - 1;
    handleTopicClick(topicsWithUrls?.[nextIndex]?.index);
  };

  const videoUrls = topicsWithUrls?.map((item) => item.videoUrl);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen((prevValue) => !prevValue);
  };

  const handleVideoEnd = () => {
    if (selectedTopic?.videoUrl) {
      handleVideoChange(selectedTopic.videoUrl);
    }
  };

  const handleVideoChange = (videoUrl) => {
    const matchingPart = topicsWithUrls.find(
      (part) => part.videoUrl === videoUrl
    );
    if (matchingPart) {
      setCurrentPartNumber(matchingPart.topic);
      updateProgress(matchingPart.topic);
    }
  };

  const updateProgress = async (currentPartNumber) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/user-progress/update`,
        {
          userId: userId,
          courseId: courseId,
          moduleId: moduleId,
          orderId: orderId,
          partNumber: currentPartNumber,
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error updating progress:", error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-col md:flex-row md:min-h-screen justify-between">
        <div
          className={`fixed inset-y-0 left-0 transform ${
            isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
          } md:relative md:translate-x-0 transition-transform duration-300 ease-in-out lg:w-80 w-56 border-r bg-main py-6 z-50`}
        >
          <div className="flex p-2 mb-4 border-t border-b justify-between">
            <div
              className={`cursor-pointer mr-4 ${
                activeTab === "menu"
                  ? "font-semibold text-xl border-b-2 border-main"
                  : ""
              }`}
              onClick={() => handleTabClick("menu")}
            >
              MENU
            </div>
            {isMobileDevice && (
              <button
                onClick={handleMobileMenuToggle}
                className="text-main p-2 focus:outline-none"
              >
                <IoCloseSharp />
              </button>
            )}
          </div>

          <h3 className="text-xl px-6 font-semibold">{moduleDetails?.name}</h3>
          {activeTab === "menu" && (
            <>
              <ul className="mt-4 pl-1 overflow-y-auto w-full h-96 px-4">
                {topicsWithUrls?.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleTopicClick(item.index)}
                    className={`py-2 flex justify-between cursor-pointer rounded-xl mb-2 px-3 ${
                      selectedTopic?.index === item.index
                        ? "play-module-active"
                        : "bg-slate-200 text-black"
                    }`}
                  >
                    <span>{item.topic}</span>
                    {selectedTopic?.index === item.index && (
                      <button className="ml-2">&#9658;</button>
                    )}
                  </li>
                ))}
              </ul>
              <div className="flex w-100 justify-between flex-wrap px-6">
                {currentIndex > 0 && (
                  <button
                    onClick={() => {
                      navigateToPrevModule();
                      setIsMobileMenuOpen(false);
                    }}
                    className="view-text2"
                  >
                    Previous Module
                  </button>
                )}

                {selectedTopic &&
                  topicsWithUrls &&
                  selectedTopic?.topic ===
                    topicsWithUrls[topicsWithUrls?.length - 1]?.topic && (
                    <>
                      <button
                        onClick={() => {
                          navigate(`/coursemodule/${courseSlug}`);
                          setIsMobileMenuOpen(false);
                        }}
                        className="view-text"
                      >
                        Exit Module
                      </button>
                    </>
                  )}
              </div>
            </>
          )}
          {activeTab === "glossary" && (
            <div className="overflow-y-auto">
              <GlossaryContent summary={moduleDetails?.summary} />
            </div>
          )}
        </div>
        <div
          className={`flex-1 bg-slate-100 ${
            isMobileMenuOpen ? "lg:ml-0 ml-56" : ""
          } transition-margin duration-300 ease-in-out`}
        >
          <div className="flex items-center gap-4 p-6">
            <button
              onClick={handleMobileMenuToggle}
              className="text-main p-2 focus:outline-none"
            >
              <svg
                className="fill-current h-6 w-6"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
            <h1 className="text-xl text-main ml-4 mt-4">
              {selectedTopic?.topic}
            </h1>
          </div>

          {selectedTopic && (
            <VideoPlayer
              handleVideoEnd={handleVideoEnd}
              handleVideoChange={handleVideoChange}
              key={selectedTopic.videoUrl}
              topic={selectedTopic?.topic}
              videoUrl={selectedTopic?.videoUrl}
              currentIndex={selectedTopic?.index}
              videoUrls={videoUrls}
              onPrevious={handlePrevious}
              onNext={handleNext}
              handleLoadedMetadata={handleLoadedMetadata}
              videoRef={videoRef}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlayModule;
