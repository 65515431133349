import React, { useState, useEffect } from "react";
import DashboardRows from "../../DashboardRows";
// import { FaPlus } from "react-icons/fa";
import FAQAccordion from "../../FAQAccordion";
import { setUser } from "../../Redux/Slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGetOrderByUserIdQuery } from "../../Redux/Services/OrderService";
import { setIsTableLoading, setOrder } from "../../Redux/Slice/OrderSlice";
import { useGetUserTransactionsQuery } from "../../Redux/Services/PaymentService";
import Loader from "../../components/Loader/Loader";
import { MdOutlineRotateRight } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { MdLaunch } from "react-icons/md";

import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Import success and failure icons
import { useMediaQuery } from "react-responsive";
import { calculateExpirationDate, calculateValidityLeft } from "../../Utils";

const formatDate = (createdAt) => {
  const date = new Date(createdAt);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" }); // Get abbreviated month name
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
const amountRenderer = (amount, currency) => {
  if (currency === "usd") {
    return `$${(amount / 100).toFixed(2)}`;
  } else {
    return amount;
  }
};

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { user } = useSelector((state) => state.auth);
  const { order } = useSelector((state) => state.order);
  const { data, isFetching, isLoading } = useGetOrderByUserIdQuery(user?._id);
  const { data: userTransactions, isLoading: UTisLoading } =
    useGetUserTransactionsQuery(user?._id);
  useEffect(() => {
    if (!isLoading && !isFetching) {
      dispatch(setOrder(data?.data));
    } else {
      dispatch(setIsTableLoading(true));
    }
  }, [isFetching, isLoading, data, dispatch]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <div className="dash-main">
      <div className="dash-user">
        <h1>Hello, {user?.name}</h1>
      </div>

      <div className="courses-main">
        <div className="courses-section flex flex-row md:flex-row items-center justify-between">
          <h4 className="text-sm md:text-xl font-semibold">My Courses</h4>
          <Link
            to={"/courses"}
            className="text-sm md:text-xl bg-green-500 hover:bg-green-700  text-white p-2 md:py-1 md:px-2 rounded-md text-center"
          >
            Buy More Courses
          </Link>
        </div>
        <div className="my-course">
          <h1>Courses</h1>
          {user?.customerType === "corporate" ? (
            <h1>Quantity Left</h1>
          ) : (
            <h1>Course Status</h1>
          )}
          <h1>Retraining Date</h1>
          <h1></h1>
        </div>

        {isLoading ? (
          <>
            <div className="m-2">
              <Loader />
            </div>
          </>
        ) : (
          <>
            {data?.data.map((orderItem, orderIndex) => (
              <>
                {orderItem.courseId
                  ?.filter(
                    (course, index) =>
                      calculateValidityLeft(
                        orderItem?.courseValidity[index]?.purchasedAt,
                        orderItem?.courseValidity[index]?.validity
                      ) > 0
                  )
                  .map((course, index) => (
                    <div key={index} className="course-grid">
                      <h1 className="text-gray-800 text-sm md:text-base font-medium col-span-3">
                        {course.title}
                      </h1>
                      {user?.customerType === "corporate" ? (
                        <h1 className="course-status">
                          {orderItem?.courseQuantity[index].quantity}
                        </h1>
                      ) : (
                        <h1 className="course-status">
                          {orderItem?.courseStatus[index].courseId ===
                            course._id &&
                          orderItem?.courseStatus[index].completionStatus
                            ? "Course Finished"
                            : "Course Not Finished"}
                        </h1>
                      )}

                      <h1 className="course-status">
                        {calculateExpirationDate(
                          orderItem?.courseValidity[index]?.purchasedAt,
                          orderItem?.courseValidity[index]?.validity
                        )}{" "}
                      </h1>

                      {user?.customerType === "corporate" ? (
                        <>
                          <div className="course-launch">
                            {isMobile ? (
                              // Mobile view, render icon instead of text
                              <Link to={`/invite`} className="launch">
                                <MdLaunch />
                              </Link>
                            ) : (
                              // Desktop view, render text
                              <Link to={`/invite`} className="launch">
                                Invite User
                              </Link>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="course-launch">
                            {isMobile ? (
                              // Mobile view, render icon instead of text
                              <Link
                                to={`/coursemodule/${course.slug}?orderId=${orderItem._id}`}
                                className="launch"
                              >
                                <MdLaunch />
                              </Link>
                            ) : (
                              // Desktop view, render text
                              <Link
                                to={`/coursemodule/${course.slug}?orderId=${orderItem._id}`}
                                className="launch"
                              >
                                Launch Courses
                              </Link>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
              </>
            ))}
            {!data?.data || data?.data?.length === 0 ? (
              <div className="no-course">
                <h3>No Courses Found</h3>
              </div>
            ) : (
              <></>
            )}
          </>
        )}

        <div className="flex items-center justify-between py-2    p-3 border-b border-gray-200 ">
          {/* <h1 className="text-gray-300 font-medium">Showing 1 courses</h1> */}
        </div>
      </div>

      <div className="transaction">
        <div className="transaction-title flex flex-row md:flex-row items-center justify-between">
          <h4 className="text-sm md:text-xl font-semibold -mb-1">
            Transaction History
          </h4>
          <div className="trans-btn">
            <Link to={"/dashboard/receipt"} className="view-btn">
              {" "}
              View All Receipt
            </Link>
          </div>
        </div>
        <div className="trans-grid">
          <h1 className="col-span-3">ID</h1>
          <h1 className="col-span-3 sm:col-span-3">Date</h1>
          <h1 className="col-span-2 sm:col-span-2">Status</h1>
          <h1 className="col-span-2">Total</h1>
          <h1 className="col-span-2 sm:col-span-2">Receipt</h1>
        </div>
        <div className=" ">
          {UTisLoading ? (
            <div className="m-2">
              <Loader />
            </div>
          ) : (
            <>
              {userTransactions?.transactions?.length === 0 && (
                <div className="no-course">
                  <h3>No Transaction Found</h3>
                </div>
              )}
              {userTransactions?.transactions?.length !== 0 &&
                userTransactions?.transactions
                  ?.slice()
                  ?.reverse()
                  ?.slice(0, 2)
                  ?.map(
                    ({
                      sessionId,
                      amount,
                      currency,
                      status,
                      createdAt,
                      _id,
                    }) => (
                      <div className="trans-grid1">
                        <h1 className="sessionId">{sessionId.slice(0, 8)}</h1>

                        <div className="trans-date">
                          <h1 className="trans-lable">
                            {formatDate(createdAt)}
                          </h1>
                        </div>
                        <div className="flex items-center col-span-2 sm:col-span-2 ">
                          {isMobile ? (
                            // Mobile view, render icons instead of text
                            <div
                              className={`${
                                status === "success"
                                  ? "text-green-500"
                                  : status === "failed"
                                  ? "text-red-500"
                                  : ""
                              } trans-icon`}
                            >
                              {status === "success" ? (
                                <FaCheckCircle />
                              ) : (
                                <FaTimesCircle />
                              )}
                            </div>
                          ) : (
                            // Desktop view, render status text
                            <h1
                              className={`${
                                status === "success"
                                  ? "trans-lable-success"
                                  : status === "failed"
                                  ? "trans-lable-fail"
                                  : ""
                              } trans-lable capitalize`}
                            >
                              {status}
                            </h1>
                          )}
                        </div>

                        <div className="trans-amt">
                          <h1>{amountRenderer(amount, currency)}</h1>
                        </div>

                        <div className="invoice">
                          <button
                            onClick={() => {
                              navigate(`/dashboard/invoice/${_id}`);
                            }}
                            className="launch"
                          >
                            {isMobile ? (
                              // Mobile view, render icon instead of text
                              <FaEye />
                            ) : (
                              // Desktop view, render text
                              <>View Receipt</>
                            )}
                          </button>
                        </div>
                      </div>
                    )
                  )}
            </>
          )}
        </div>
        <div className="no-course">
          {/* <h1 className="text-gray-300 font-medium col-span-2">
            Showing 1 courses
          </h1> */}
        </div>
      </div>

      <div className="faq">
        <h3>Frequently Asked Questions</h3>
        <div className="faq-text">
          <h4>Is there a time limit to complete a Course?</h4>
          <span>
            Once a customer begins the safety training courses ,he/she will have
            up to a year to complete the program.Consideriing the course
            lengthOnce a customer begins the safety training courses ,he/she
            will have up to a year to complete the program.Consideriing the
            course length,
          </span>
        </div>
        <div className="faq-data">
          {/* <FAQAccordion faqData={faqData} /> */}
          {/* {faqAnswers.map((faq) => {
            return (
              <>
                <div className="shadow flex items-center justify-between w-full bg-white-100 py-3 px-2 border border-gray-300  rounded text-xs text-gray-800">
                  {faq.q}
                  {faq.icon}
                </div>
              </>
            );
          })} */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
