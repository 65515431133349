import React from "react";
import Blog from "./BlogSection";
import About from "./AboutSection";
import Service from "./ServiceSection";
import HomeSlider from "./SliderSection";
import Campus from "./CampusSection";
import Course from "./CourseSection";
import Event from "./EventSection";
import Counter from "./CounterSection";
import Testimonial from "./TestimonialSection";
import ScrollToTop from "../../components/ScrollTop";
import Preloader from "../../components/Preloader/index";
import { useDispatch, useSelector } from "react-redux";
import { useGetCourseQuery } from "../../Redux/Services/Courseservice";
import { useState, useEffect } from "react";

const HomeMain = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate data loading delayS
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {isLoading ? <Preloader /> : ""}
      <div className="react-wrapper">
        <div className="react-wrapper-inner">
          <HomeSlider />
          <Course />
          <About />
          <Counter />
          <ScrollToTop scrollClassName="home react__up___scroll" />
        </div>
      </div>
    </>
  );
};

export default HomeMain;
