import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import SingleCourseThree from "../../components/Course/SingleCourseThree";

// import courses from "../../data/Courses.json";
import { useGetCourseQuery } from "../../Redux/Services/Courseservice";
import { useDispatch, useSelector } from "react-redux";
import { setCourse } from "../../Redux/Slice/CourseSlice";
import Loader from "../../components/Loader/Loader";
import { useGetCategoriesQuery } from "../../Redux/Services/CategoryService";

const CourseGridMain = () => {
  const dispatch = useDispatch();
  const { data: course, isFetching, isLoading } = useGetCourseQuery();
  const { data: category, isLoading: isCategoryLoading } =
    useGetCategoriesQuery();
  const [courses, setCourses] = useState(course?.data);
  const [categories, setCategories] = useState(category);

  useEffect(() => {
    if (!isLoading && !isFetching && !isCategoryLoading) {
      setCourses(course?.data);
      setCategories(category);
      dispatch(setCourse(course?.data));
    }
  }, [dispatch, course, isFetching, isLoading, isCategoryLoading]);

  const handleChangeCategory = (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value === "all") {
      setCourses(course?.data);
    } else {
      const filtered = course?.data?.filter(
        (item) => item.categoryId === value
      );
      setCourses(filtered);
    }
  };

  const handleFilterPrice = (e) => {
    e.preventDefault();
    if (e.target.value === "all") {
      setCourses(course);
    } else if (e.target.value === "free") {
      let filterCourses = course?.data?.filter((item) => item.price === 0);
      setCourses(filterCourses);
    } else if (e.target.value === "paid") {
      let filterCourses = course?.data?.filter((item) => item.price > 0);
      setCourses(filterCourses);
    }
  };

  return (
    <>
      <div className="react-course-filter back__course__page_grid pb---40 pt---40">
        <div className="container pb---70">
          <div className="row align-items-center back-vertical-middle shorting__course mb-50">
            <div className="col-md-2">
              <div className="all__icons">
                <div className="list__icons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-sliders"
                  >
                    <line x1="4" y1="21" x2="4" y2="14"></line>
                    <line x1="4" y1="10" x2="4" y2="3"></line>
                    <line x1="12" y1="21" x2="12" y2="12"></line>
                    <line x1="12" y1="8" x2="12" y2="3"></line>
                    <line x1="20" y1="21" x2="20" y2="16"></line>
                    <line x1="20" y1="12" x2="20" y2="3"></line>
                    <line x1="1" y1="14" x2="7" y2="14"></line>
                    <line x1="9" y1="8" x2="15" y2="8"></line>
                    <line x1="17" y1="16" x2="23" y2="16"></line>
                  </svg>
                </div>
                <div className="result-count">Filters</div>
              </div>
            </div>
            <div className="col-md-10 text-right">
              <select
                className="from-control category"
                onChange={handleChangeCategory}
              >
                <option value="all">All Categories</option>
                {categories?.map((item, index) => (
                  <>
                    <option key={index} value={item?._id}>
                      {item?.title}
                    </option>
                  </>
                ))}
              </select>

              <select
                onChange={(e) => handleFilterPrice(e)}
                className="from-control price"
              >
                <option value={"all"}>All Prices</option>
                <option value={"free"}>Free Courses</option>
                <option value={"paid"}>Paid Courses</option>
              </select>
            </div>
          </div>

          <div className="row">
            {isLoading ? (
              <div className="m-4">
                <Loader />
              </div>
            ) : (
              <>
                {courses?.length === 0 && <div>No data</div>}
                {courses &&
                  courses?.map((data, index) => {
                    return (
                      <div key={index} className="col-lg-4">
                        {
                          <SingleCourseThree
                            courseID={data._id}
                            courseImg={`${data.coverImage}`}
                            courseTitle={data.title}
                            coursePrice={data.price}
                            courseRating={data.rating}
                            courseOverview={data.overview}
                            courseSlug={data.slug}
                          />
                        }
                      </div>
                    );
                  })}
              </>
            )}
          </div>
          <ul className="back-pagination pt---20">
            <li>
              <Link to="#">1</Link>
            </li>
            <li>
              <Link to="#">2</Link>
            </li>
            <li className="back-next">
              <Link to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CourseGridMain;
