import React from "react";
import ScrollToTop from "../../components/ScrollTop";
import Preloader from "../../components/Preloader/index";
import { useDispatch, useSelector } from "react-redux";
import { useGetCourseQuery } from "../../Redux/Services/Courseservice";
import { useState, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb/index";
import Table from "../Cart/Table";
import Footer from "../Footer/index";
import Header from "../Header/index";
const Cart = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulate data loading delayS
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {isLoading ? <Preloader /> : ""}
      <Header/>
      <div className="react-wrapper">
        <div className="react-wrapper-inner">
          <Breadcrumb pageTitle="Cart" />
          <Table />
          <ScrollToTop scrollClassName="home react__up___scroll" />
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Cart;
