import axios from "axios";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL } from "../Utils/constant";

const Cancel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get("orderId");
  const paymentId = new URLSearchParams(location.search).get("paymentId");
  const transactionId = new URLSearchParams(location.search).get(
    "transactionId"
  );

  useEffect(() => {
    const UpdateOrderPaymentStatus = async () => {
      try {
        const response = await axios.put(
          `${BASE_URL}/api/updateOrderPaymentStatus`,
          {
            orderId: orderId,
            paymentId: paymentId,
            transactionId: transactionId,
            success: false,
          }
        );
        if (response.data.success) {
          toast.error("Payment Failed");
          navigate("/");
        }
      } catch (error) {
        toast.error(error.message);
      }
    };
    UpdateOrderPaymentStatus();
  }, [orderId, paymentId, transactionId]);

  return <div></div>;
};

export default Cancel;
