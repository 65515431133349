import { jwtDecode } from "jwt-decode"; // Importing jwtDecode as a named export

export const isValidAdmin = async (token) => {
  try {
    const { role, _id } = jwtDecode(token);

    if (role === "member" && _id) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
  }
};
