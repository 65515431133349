import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { isValidEmail } from "../../Utils/validators";
import {
  useLoginMutation,
  useVerifyRecaptchaMutation,
} from "../../Redux/Services/authServices";
import {
  setAccessToken,
  setRefreshToken,
  setUser,
} from "../../Redux/Slice/authSlice";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const LoginMain = () => {
  const dispatch = useDispatch();
  const recaptcha = useRef();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [login] = useLoginMutation();
  const [verifyRecaptcha] = useVerifyRecaptchaMutation();

  const handleLogin = async (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();

    if (!data.email) {
      setEmailError("Email is required");
      return;
    }
    if (!isValidEmail(data.email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    if (!data.password) {
      setPasswordError("Password is required");
      return;
    }

    if (!captchaValue) {
      toast.info("Please verify reCAPTCHA!");
    } else {
      try {
        const recaptchaResponse = await verifyRecaptcha({
          captchaValue: captchaValue,
        });
        if (recaptchaResponse?.data?.success) {
          const response = await login({
            email: data.email,
            password: data.password,
          });

          const userData = response?.data?.data;
          if (response?.data?.data) {
            dispatch(setUser(response?.data?.data));
            dispatch(setAccessToken(response?.data?.data?.accessToken));
            dispatch(setRefreshToken(response?.data?.data?.refreshToken));
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem(
              "authToken",
              response?.data?.data?.accessToken
            );
            localStorage.setItem(
              "refreshToken",
              response?.data?.data?.refreshToken
            );
            toast.success("Login Sucessfully");
            setTimeout(() => {
              alert("Login Successfully");
              navigate("/dashboard");
            
            }, 6000);            
          } else if (response && response.error) {
            console.log(response.error);
            setApiError(response?.error?.data?.msg);
          } else {
            // Handle unexpected response format
            console.error("Unexpected response format:", response);
            toast.error("An unexpected error occurred");
          }
        } else {
          console.log(recaptchaResponse);
          recaptcha.current.reset();
          toast.error("failed to Verify captcha");
        }
      } catch (err) {
        console.log(err);
        toast.error("An unexpected error occurred"); // Show error using react-toastify
      }
    }
  };

  return (
    <>
      <div className="react-login-page react-signup-page pt---120 pb---120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-right-form">
                <form onSubmit={handleLogin}>
                  <div className="login-top">
                    <h3>Login</h3>
                    <p className="login-text">
                      <strong>
                      Don't have an account yet?{" "}
                    </strong>
                    </p>
                    No Worries! You can create account when you purchase your courses.
                    {apiError && (
                      <div className="text-danger text-center">{apiError}</div>
                    )}
                    {emailError && (
                      <div className="text-red-400 ">{emailError}</div>
                    )}
                    {passwordError && (
                      <div className="text-red-400 ">{passwordError}</div>
                    )}
                  </div>
                  <p>
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={data.email}
                      onChange={(event) =>
                        setData({ ...data, email: event.target.value })
                      }
                    />
                  </p>
                  <p>
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={data.password}
                      onChange={(event) =>
                        setData({ ...data, password: event.target.value })
                      }
                    />
                  </p>
                  <div className="back-check-box">
                    <input type="checkbox" id="box-1" /> Remember me
                    <p>
                      <Link to={`/forgotpassword`}>Forget password?</Link>
                    </p>
                  </div>
                  <div className="flex justify-center mt-2 mb-4">
                    <ReCAPTCHA
                      ref={recaptcha}
                      sitekey={process.env.REACT_APP_SITE_KEY}
                    />
                  </div>
                  <button type="submit" id="button" name="submit">
                    LogIn{" "}
                  </button>
                 
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginMain;
